import React from "react";
import { useTheme } from "@emotion/react";
import { IconProfileMultiple } from "@hubble/icons";
import { Colors, Flex, HubbleCard, IconBadge, Text } from "@gemini-ui/design-system";
import { REFERRAL_TERMS_LINK } from "@gemini-ui/pages/Credit/CreditDashboard/modules/Dashboard/components/Referral/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export const ValidReferral = () => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <React.Fragment>
      <HubbleCard variant="filled" customBgColor={Colors.purple[50]} density="md" pt={2} pb={2}>
        <Flex alignItems="center">
          <IconBadge
            size="lg"
            backgroundColor={colorScheme.status.default.background.reward}
            icon={<IconProfileMultiple color={colorScheme.status.default.content.reward} />}
          />
          <Flex.Column ml={1.5}>
            <Text.Body bold mb={0.5}>
              {intl.formatMessage({ defaultMessage: "You are referred for a special offer" })}
            </Text.Body>
            <Text.Body size="sm">
              {intl.formatMessage({
                defaultMessage: "Get $250 in crypto! $50 when you are approved, $200 after meeting spend requirements.",
              })}
            </Text.Body>
          </Flex.Column>
        </Flex>
      </HubbleCard>
      <Text.Body size="xs" color={colorScheme.content.secondary} mt={1.5} mb={2}>
        {intl.formatMessage({
          defaultMessage:
            "You get $50 in the crypto of your choice when you are approved for the Gemini Credit Card using this referral and complete your first billing cycle with at least one qualifying purchase. You will also be eligible for $200 in crypto of your choice if you are approved before March 31st and spend $3,000 in your first 90 days.",
        })}{" "}
        <Text.Link
          href={REFERRAL_TERMS_LINK}
          target="_blank"
          rel="noopener noreferrer"
          color={colorScheme.content.secondary}
        >
          {intl.formatMessage({ defaultMessage: "Referral Program Terms" })}
        </Text.Link>
        {" & "}
        <Text.Link
          href="https://www.gemini.com/legal/credit-card-intro-promo-terms"
          target="_blank"
          rel="noopener noreferrer"
          color={colorScheme.content.secondary}
        >
          {intl.formatMessage({ defaultMessage: "Intro Promo Terms Apply." })}
        </Text.Link>
      </Text.Body>
    </React.Fragment>
  );
};
