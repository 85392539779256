import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { EVENTS, optimizelyClient } from "@gemini-ui/analytics";
import { rewardApi } from "@gemini-ui/client/api";
import { Currency, RewardCurrency } from "@gemini-ui/client/credit";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { XStateSend } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { trackAnalyticsEvent } from "@gemini-ui/pages/Credit/CreditApplication/tracking/analytics";
import { getAccountData } from "@gemini-ui/pages/Credit/CreditDashboard/utils";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";
import { getError } from "@gemini-ui/utils/error";

export const useSelectReward = () => {
  const { showAlert } = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAuthUsersInviteEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREDIT_CARD_AUTH_USERS_INVITE
  );

  const handleSelectReward = async (currency: Currency, send: XStateSend) => {
    const rewardCurrency: RewardCurrency = { currency };
    setIsSubmitting(true);

    try {
      const { creditAccountId } = await getAccountData();
      await rewardApi.setRewardCurrency(creditAccountId, rewardCurrency);

      try {
        trackAnalyticsEvent({
          trackingEvent: EVENTS.CC_CHOOSE_CRYPTO.name,
          properties: { applicationData: { rewardCurrency } },
        });
      } catch (error) {
        Sentry.captureException(error);
      }
      if (isAuthUsersInviteEnabled) {
        send({ type: ClientState.AUTH_USER_INVITE });
      } else {
        return window.location.assign(CC_PATH.DASHBOARD);
      }
    } catch (error) {
      showAlert({ type: AlertTypes.ERROR, message: getError(error) });
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSelectReward };
};
