import { datadogLogs, LogsEvent } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { isProdUrl } from "@gemini-ui/analytics";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { DataDogCredentials } from "@gemini-ui/constants/initialData/datadog";
import { User } from "@gemini-ui/constants/templateProps/users";

export const beforeSend = (event: LogsEvent) => {
  const IGNORE_ERRORS = [
    "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose", // Facebook borked
    "fb_xd_fragment", // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See https://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived", // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ];
  if (IGNORE_ERRORS.includes(event.error?.message) || event.view.url.includes("devNotes")) {
    return false;
  }
  return true;
};
export const initializeDataDogLogging = (credentials: DataDogCredentials, user?: User) => {
  const isDataDogLoggingEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG_LOGGING);
  if (!isDataDogLoggingEnabled) {
    return;
  }

  datadogLogs.init({
    clientToken: credentials.tradingDashBoardClientToken,
    site: "datadoghq.com",
    env: isProdUrl() ? "prod" : "qa",
    service: "web-frontend",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "debug"],
    sessionSampleRate: 100,
    beforeSend,
  });

  if (user) {
    datadogLogs.setUser({
      id: user.mixpanelId,
      email: user.email,
      countryCode: user.countryCode,
      lang: user.lang,
    });
  }
};
export const initializeDataDogRUM = (credentials: DataDogCredentials, user: User) => {
  const isDataDogEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG);

  if (!isDataDogEnabled) return;

  const sessionSampleRate = optimizelyClient.getFeatureVariableInteger(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "sampleRate"
  );
  const trackUserInteractions = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackInteractions"
  );
  const trackSessionAcrossSubdomains = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackSessionAcrossSubdomains"
  );

  const trackLongTasks = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackLongTasks"
  );
  const trackResources = optimizelyClient.getFeatureVariableBoolean(
    OPTIMIZELY_FEATURE_FLAGS.WEB_DATADOG,
    "trackResources"
  );

  datadogRum.init({
    applicationId: credentials.tradingDashBoardApplicationId,
    clientToken: credentials.tradingDashBoardClientToken,
    site: "datadoghq.com",
    service: "web-frontend",
    env: isProdUrl() ? "prod" : "qa",
    sessionSampleRate,
    trackUserInteractions,
    trackLongTasks,
    trackResources,
    trackSessionAcrossSubdomains,
  });

  if (user?.mixpanelId) {
    datadogRum.setUser({
      id: user?.mixpanelId,
      email: user.email,
      name: user.fullName,
      countryCode: user.countryCode,
      lang: user.lang,
    });
  }
};
