import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import {
  GeminiPublicKeyCredentialCreationOptions,
  GeminiPublicKeyCredentialRequestOptions,
  GeminiSubmitCredentialAuthPayload,
} from "@gemini-ui/pages/Authy/constants";
import axios, { AxiosResponse } from "@gemini-ui/services/axios";
import { base64UrlDecode, base64UrlEncode } from "@gemini-ui/utils/encoders";

// credentials.create utils to create new keys
const decodeCredentialOpts = (opts: GeminiPublicKeyCredentialCreationOptions) => {
  const clone = JSON.parse(JSON.stringify(opts));

  const excludeCreds = clone.excludeCredentials.map(cred => {
    return Object.assign(cred, { id: base64UrlDecode(cred.id) });
  });

  return Object.assign(clone, {
    user: Object.assign(clone.user, { id: base64UrlDecode(opts.user.id) }),
    challenge: base64UrlDecode(opts.challenge),
    excludeCredentials: excludeCreds,
    authenticatorSelection: opts.authenticatorSelection,
  });
};

const encodeNewCredential = (pubKey: PublicKeyCredential) => {
  const clientExtensionResults = pubKey.getClientExtensionResults();

  const response = pubKey.response as AuthenticatorAttestationResponse;
  return {
    type: pubKey.type,
    id: pubKey.id,
    response: {
      // improved the param types slightly here but these values return ArrayBuffers from the w3c types
      // the buffer library currently being used doesn't support that type so using unknown for now to satisfy compiler
      // these responses are working as is so going to leave for now but todo we should update that buffer library
      attestationObject: base64UrlEncode(response.attestationObject as unknown as string),
      clientDataJSON: base64UrlEncode(response.clientDataJSON as unknown as string),
    },
    clientExtensionResults,
  };
};

/* istanbul ignore next */
const sendRegisteredCredential = (credential, signed: string, isPasskey: boolean) => {
  return axios.post(
    jsRoutes.com.gemini.web.server.auth.controllers.WebAuthnRegistrationController.finishRegistration().url,
    {
      credential: credential,
      signed: signed,
      passkeyType: isPasskey ? "WebInitiatedPasskey" : null,
    }
  );
};

type CreateCredentialProps = {
  onSuccess: () => void;
  nickname: string;
  isPasskey?: boolean;
};
export const createCredential = async ({ onSuccess, isPasskey, nickname }: CreateCredentialProps) => {
  const isPasswordLessEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREATE_PASSKEYS_PASSWORDLESS
  );
  /* istanbul ignore next */
  const { data } = await axios.post(
    jsRoutes.com.gemini.web.server.auth.controllers.WebAuthnRegistrationController.post().url,
    {
      nickname,
      useForPasskeyLogin: isPasskey && isPasswordLessEnabled,
    }
  );
  const publicKey = decodeCredentialOpts(data.pending.publicKeyCredentialCreationOptions);

  let publicKeyFn = publicKey;
  // temp FE hack to open QR code only for passkeys on chrome
  const isChrome = /chrome/i.test(navigator.userAgent);
  // safari already has good native support, keep settings unchanged
  if (isPasskey && isChrome) {
    const CROSS_PLATFORM = "cross-platform";
    publicKeyFn = {
      ...publicKey,
      authenticatorSelection: {
        ...publicKey.authenticatorSelection,
        // https://developer.mozilla.org/en-US/docs/Web/API/PublicKeyCredential/authenticatorAttachment#value
        authenticatorAttachment: CROSS_PLATFORM,
      },
    };
  }
  return window.navigator.credentials
    .create({ publicKey: publicKeyFn })
    .then(assert => {
      // narrow type response
      if (assert.type === "public-key") return encodeNewCredential(assert);
      return null;
    })
    .then(credential => sendRegisteredCredential(credential, data.signed, isPasskey))
    .then(() => {
      onSuccess();
    })
    .catch(e => {
      throw e;
    });
};

// credentials.get utils to complete 2FA/password-less
export const decodeAssertionOpts = (opts: GeminiPublicKeyCredentialRequestOptions) => {
  const clone = JSON.parse(JSON.stringify(opts));

  const allowCredentials =
    clone.allowCredentials &&
    clone.allowCredentials.map(cred => {
      return Object.assign(cred, { id: base64UrlDecode(cred.id) });
    });

  return Object.assign(clone, {
    challenge: base64UrlDecode(opts.challenge),
    allowCredentials,
  });
};

export const encodeAssertion = (rawAssertion: PublicKeyCredential): GeminiSubmitCredentialAuthPayload => {
  const clientExtensionResults = rawAssertion.getClientExtensionResults();
  const response = rawAssertion.response as AuthenticatorAssertionResponse;

  return {
    type: rawAssertion.type,
    id: rawAssertion.id,
    response: {
      // improved the param types slightly here but these values return ArrayBuffers from the w3c types
      // the buffer library currently being used doesn't support that type so using unknown for now to satisfy compiler
      // these responses are working as is so going to leave for now but todo we should update that buffer library
      authenticatorData: base64UrlEncode(response.authenticatorData as unknown as string),
      clientDataJSON: base64UrlEncode(response.clientDataJSON as unknown as string),
      signature: base64UrlEncode(response.signature as unknown as string),
      userHandle: response.userHandle && base64UrlEncode(response.userHandle as unknown as string),
    },
    clientExtensionResults,
  };
};

export const sendCredentialAssertion = (
  credential: GeminiSubmitCredentialAuthPayload,
  signed: string,
  redirect: string,
  email?: string
): Promise<AxiosResponse<{ redirect: string }>> => {
  return axios.post(jsRoutes.com.gemini.web.server.auth.controllers.AuthyController.finishAuthentication(email).url, {
    response: {
      credential: credential,
      signedRequest: signed,
    },
    redirect: redirect,
  });
};

export const sendCredentialAssertionSignin = (
  credential: GeminiSubmitCredentialAuthPayload,
  signed: string,
  redirect: string
): Promise<AxiosResponse<{ redirect: string }>> => {
  return axios.post("/signin/passwordless", {
    response: {
      credential: credential,
      signedRequest: signed,
    },
    redirect: redirect,
  });
};
