import { IconNumber1Outlined, IconNumber2Outlined, IconNumber3Outlined } from "@hubble/icons";
import { IntlShape } from "react-intl";
import { Button } from "@gemini-ui/design-system";

export const getReferralDetailsItems = (intl: IntlShape, handleShareLink: () => void) => [
  {
    icon: <IconNumber1Outlined />,
    title: intl.formatMessage({ defaultMessage: "Share your referral link" }),
    description: intl.formatMessage({ defaultMessage: "Via text, email or social media." }),
    button: (
      <Button.Primary size="sm" onClick={handleShareLink}>
        {intl.formatMessage({ defaultMessage: "Share link" })}
      </Button.Primary>
    ),
  },
  {
    icon: <IconNumber2Outlined />,
    title: intl.formatMessage({ defaultMessage: "They use your link to apply" }),
    description: intl.formatMessage({ defaultMessage: "And are approved for the Gemini Credit Card." }),
    button: null,
  },
  {
    icon: <IconNumber3Outlined />,
    title: intl.formatMessage({ defaultMessage: "You get $50" }),
    description: intl.formatMessage({ defaultMessage: "In the crypto reward chosen." }),
    button: null,
  },
];

export const REFERRAL_TERMS_LINK = "https://www.gemini.com/legal/credit-card-referral-program-terms";
