import { CurrencyPairDetail } from "@gemini-common/scripts/constants/currencies";
import { NotificationSettings } from "@gemini-ui/constants";
import {
  CandleInterval,
  ChartConfig,
  ChartType,
  HistoricalTradesState,
  OrderFormUnDockedSettings,
  OrdersState,
  PairKeyedCandleData,
  PairKeyedChartConfig,
  PairKeyedChartState,
  PairKeyedHistoricalPrices,
  PairKeyedLastPrice,
  PairKeyedOrderBook,
  TradeState,
} from "@gemini-ui/pages/ActiveTrader/Spot/constants";

export interface OrderBookReduxState {
  candleData: PairKeyedCandleData;
  candleInterval: CandleInterval;
  chartConfig: PairKeyedChartConfig;
  orderBookData: PairKeyedOrderBook;
  websocketLastPrice: PairKeyedLastPrice;
  historicalPrices: PairKeyedHistoricalPrices;
  supportedPairs?: CurrencyPairDetail[];
  chartState?: PairKeyedChartState;
  allPairDetails: TradeState["allPairDetails"];
  orders: OrdersState;
  historicalTrades: HistoricalTradesState;
  notificationSettings: NotificationSettings;
  subaccountHashid: string;
}

export type ChartState = "Trading View" | "Depth";

const TRADING_VIEW: ChartState = "Trading View";
const DEPTH: ChartState = "Depth";
export type ChartAlignmentState = "Horizontal" | "Vertical" | "None";
const HORIZONTAL: ChartAlignmentState = "Horizontal";
const VERTICAL: ChartAlignmentState = "Vertical";
const NONE: ChartAlignmentState = "None";

export const ChartStates = {
  TRADING_VIEW,
  DEPTH,
};

export const CANDLE_INTERVAL_DEFAULT: CandleInterval = "candles_1m";
const CHART_TYPE_DEFAULT: ChartType = "hollow_candle";

export const CHART_CONFIG_KEY = "trade/CHART_CONFIG_KEY";
export const CHART_CONFIG_DEFAULT: ChartConfig = {
  [ChartStates.TRADING_VIEW]: {
    candleInterval: CANDLE_INTERVAL_DEFAULT,
    chartType: CHART_TYPE_DEFAULT,
    showOpenOrders: true,
    showRecentlyFilledOrders: true,
  },
} as const;

export const ORDER_FORM_UNDOCKED_SETTINGS_KEY = "trade/ORDER_FORM_UNDOCKED_SETTINGS_KEY";
export const ORDER_FORM_UNDOCKED_SETTINGS_DEFAULT: OrderFormUnDockedSettings = {
  showOrderFormCoachMark: true,
  orderFormVisible: true,
} as const;

export enum TVToolbarActions {
  CHART_RESET = "chartReset",
  OPEN_INDICATORS_DIALOG = "insertIndicator",
}

export const ChartAlignmentStates = {
  HORIZONTAL,
  VERTICAL,
  NONE,
};

export const CHART_EVENTS = {
  PERIODICITY: {
    name: "Change Periodicity",
    properties: {
      PERIODICITY: "Periodicity",
    },
  },
  TYPE: {
    name: "Change Chart Type",
    properties: {
      CHART_TYPE: "Chart Type",
    },
  },
  CANDLE: {
    name: "Change Candle Type",
    properties: {
      CANDLE_TYPE: "Candle Type",
    },
  },
  ZOOM_IN: {
    name: "Click Zoom-in",
  },
  ZOOM_OUT: {
    name: "Click Zoom-out",
  },
  ZOOM_RESET: {
    name: "Click Reset Zoom",
  },
  EXPAND_CHART: {
    name: "Click Expand chart",
    properties: {
      ACTION: "action",
    },
  },
  INDICATOR_DROPDOWN: {
    name: "Trigger Indicator Dropdown",
  },
};

export const CHART_TEST_IDS = {
  unsupportedTokenBanner: "unsupported-token-banner",
  suspendedPairBanner: "suspended-pair-banner",
};

export const TV_LIMIT_ORDER_PREVIEW_TICKET = "tvLimitOrderPreviewTicket";
export const TV_LIMIT_ORDER_PREVIEW_CHART = "tvLimitOrderPreviewChart";

export const ON_CHART_EVENTS = {
  SET_FULLSCREEN: "setFullScreen",
} as const;
