import { Component, Dispatch, Fragment, SetStateAction } from "react";
import * as Sentry from "@sentry/browser";
import { countriesList } from "@gemini-common/scripts/constants/Countries";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Refresher } from "@gemini-ui/constants/refresher";
import { GeminiEntity, Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Button, Flex, Modal } from "@gemini-ui/design-system";
import { GENERIC_3DS_ERROR } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import ChallengeForm from "@gemini-ui/pages/RetailTrade/PaymentMethod/WorldPayIframe/ChallengeForm";
import DeviceData from "@gemini-ui/pages/RetailTrade/PaymentMethod/WorldPayIframe/DeviceData";
import {
  AddDebitCardFlowState,
  BillingAddress,
  DebitCardInfo,
  INITIAL_ADDRESS_FIELDS,
  ToggleDebitCardModal,
} from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { DebitCardModalState } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/types";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import AddBillingAddressManualModal from "@gemini-ui/pages/settings/BankSettings/debit/AddBillingAddressManualModal";
import AddBillingAddressModal from "@gemini-ui/pages/settings/BankSettings/debit/AddBillingAddressModal";
import AddDebitCardModal from "@gemini-ui/pages/settings/BankSettings/debit/AddDebitCardModal";
import { DebitCardErrorModal } from "@gemini-ui/pages/settings/BankSettings/debit/DebitCardErrorModal";
import SelectCountryModal from "@gemini-ui/pages/settings/BankSettings/debit/SelectCountryModal";
import SelectDebitCurrencyModal from "@gemini-ui/pages/settings/BankSettings/debit/SelectDebitCurrencyModal";
import SelectExchangeModal from "@gemini-ui/pages/settings/BankSettings/debit/SelectExchangeModal";
import {
  handleAuthenticateTransaction,
  handleDebitCardSubmit,
  handleVerifyChallenge,
} from "@gemini-ui/pages/settings/BankSettings/debit/services/apiLayer";
import { TransactionsSentModal } from "@gemini-ui/pages/settings/BankSettings/debit/TransactionsSentModal";
import {
  AuthenticateRespone,
  DeviceDataInitialisation,
  PostChallengeFormCallbacksType,
} from "@gemini-ui/pages/settings/BankSettings/debit/types";
import VerifyYourCardStepOneModal from "@gemini-ui/pages/settings/BankSettings/debit/VerifyYourCardModalStepOne";
import VerifyYourCardStepTwoModal from "@gemini-ui/pages/settings/BankSettings/debit/VerifyYourCardModalStepTwo";
import { getPostalCode } from "@gemini-ui/pages/settings/BankSettings/utils/getPostalCode";
import { handle3DSErrorState } from "@gemini-ui/pages/settings/BankSettings/utils/handle3DSErrorState";
import axios from "@gemini-ui/services/axios";
import { DebitCardType, UserAddressType } from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape, withIntl } from "@gemini-ui/utils/intl";

const initialModalState = {
  selectDebitCurrencyVisible: false,
  selectCountryVisible: false,
  addDebitCardVisible: false,
  addBillingAddressVisible: false,
  addBillingAddressManualVisible: false,
  verifyCardStepOneVisible: false,
  transactionsSentVisible: false,
  verifyCardStepTwoVisible: false,
  tooManyAttemptsVisible: false,
  cardDeclinedVisible: false,
  showDebitCardSuccessModal: false,
  selectExchangeAccountVisible: false,
  showChallengeFormIframe: false,
};

const initialState = {
  ...initialModalState,
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  countryFullName: "",
  cardHolderName: "",
  sessionToken: "",
  uuid: "",
  cardProps: null,
  selectedCurrency: "USD",
  maxMicroDeposit: null,
  minMicroDeposit: null,
  isExistingCard: false,
  useDefaultAddress: true,
  isManualAddressMode: false,
  openDebitCardErrorModal: false,
  debitCardErrorHeading: "",
  debitCardErrorDescription: "",
  subaccounts: [],
  deviceDataJWT: "",
  deviceDataURL: "",
  bin: "",
  internalReference: "",
  showDeviceDataIframe: false,
  unVerifiedTokenID: "",
  authenticateResponse: null,
  showChallengeFormIframe: false,
} as const;

export interface AddDebitCardFlowProps {
  defaultFiat: CurrencyShortNameFiat;
  supportedFiat: CurrencyShortNameFiat[];
  initialState?: Partial<AddDebitCardFlowState>;
  onPaymentMethodUpdate?: (paymentMethod: DebitCardType) => void;
  onClose?: () => void;
  onBack?: () => void;
  settingsFlow?: boolean;
  requestRefresh: Refresher["requestRefresh"];
  intl: IntlShape;
  geminiEntity?: GeminiEntity;
  defaultCardHolderName?: string;
  userDetails?: UserAddressType;
  openAddPaymentMethodsModal?: () => void;
  closeAddPaymentMethodsModal?: () => void;
  isOpen?: boolean;
  openSelectPaymentMethodModal?: () => void;
  scope?: PaymentTypeScope;
  onSuccessCallback?: () => void;
  subaccounts?: Subaccount[];
  selectedSubaccountHashid?: string;
  setSelectedSubaccountHashid?: (hashid: string) => void;
  subaccountHashid?: string;
}

export type AddPaymentMethodsDebitCardProps = Omit<
  Partial<AddDebitCardFlowProps>,
  "defaultFiat" | "supportedFiat" | "intl" | "geminiEntity" | "defaultCardHolderName | userDetails"
>;

export const processChallengeFormSubmit = (
  state: AddDebitCardFlowState,
  setState: Dispatch<SetStateAction<AddDebitCardFlowState>>,
  props: AddDebitCardFlowProps,
  callbacks: PostChallengeFormCallbacksType
) => {
  const {
    cardHolderName,
    unVerifiedTokenID,
    internalReference,
    selectedCurrency,
    country,
    address1,
    address2,
    city,
    state: stateAddress,
    zip,
  } = state;

  const { onToggle, verifyCard, openDebitCardErrorModal, setShowDeviceDataIframe } = callbacks;

  handleVerifyChallenge(unVerifiedTokenID, internalReference)
    .then(() => {
      const { name, properties } = EVENTS.ADD_PAYMENT_3DS_VERIFICATION_SUCCESS;
      track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
      const payload = {
        billingAddress: {
          cardHolderName: cardHolderName,
          streetLine1: address1,
          streetLine2: address2,
          city: city,
          state: stateAddress,
          countryCode: country,
          postalCode: getPostalCode(zip),
        },
        cardHolderName: cardHolderName,
        verificationCurrency: selectedCurrency,
        tokenUUID: unVerifiedTokenID,
      };

      handleDebitCardSubmit(
        payload,
        selectedCurrency,
        country,
        props.selectedSubaccountHashid || props.subaccountHashid,
        onToggle,
        verifyCard,
        openDebitCardErrorModal,
        false,
        undefined,
        setShowDeviceDataIframe,
        state.showChallengeFormIframe
      );
    })
    .catch(error => {
      const { name, properties } = EVENTS.ADD_PAYMENT_3DS_VERIFICATION_FAILED;
      track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
      setShowDeviceDataIframe(false);
      onToggle(DebitCardModalState.addDebitCardVisible)();
      setState({ unVerifiedTokenID });
      handle3DSErrorState(error, openDebitCardErrorModal);
    });
};

export const handleOpen3DSChallengeIframe = (
  displayName: string,
  authenticateResponse: AuthenticateRespone,
  onToggle: ToggleDebitCardModal,
  openDebitCardErrorModal: (debitCardErrorHeading: string, debitCardErrorDescription?: string) => void,
  setAuthenticateResponse: (authenticateResponse: AuthenticateRespone) => void
) => {
  switch (displayName) {
    case "3DS":
      setAuthenticateResponse(authenticateResponse);
      onToggle(DebitCardModalState.showChallengeFormIframe)();
      break;
    default:
      openDebitCardErrorModal(GENERIC_3DS_ERROR);
      break;
  }
};

export class AddDebitCardFlow extends Component<AddDebitCardFlowProps, AddDebitCardFlowState> {
  isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);
  isAddPaymentMethodsRevamped = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  isDerivativeDebitCardAdditionEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
  );

  isDerivativeSubaccount = Boolean(
    this.props?.subaccounts?.find(({ hashid }) => hashid === this.props.subaccountHashid)?.derivatives
  );
  isCardAllowedForDerivativeAccount = this.isDerivativeDebitCardAdditionEnabled && this.isDerivativeSubaccount;
  static contextType = AlertContext;
  initialAddressState = {
    country: this.props.userDetails?.address.country,
    state: "",
    zip: "",
    city: "",
    address1: "",
    address2: "",
    countryFullName: countriesList[this.props.userDetails?.address.country.toLowerCase()],
  };
  state = {
    ...(this.props.initialState || initialState),
    selectedCurrency: this.isNewDebitCardFlowEnabled ? this.props.defaultFiat : "USD",
    addBillingAddressManualVisible:
      this.isNewDebitCardFlowEnabled && !this.isCardAllowedForDerivativeAccount ? this.props.isOpen : false,
    selectExchangeAccountVisible: this.isCardAllowedForDerivativeAccount ? this.props.isOpen : false,
    selectDebitCurrencyVisible: this.isNewDebitCardFlowEnabled ? false : this.props.isOpen,
    ...this.initialAddressState,
  };

  googleGeocodingActive = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_GOOGLE_GEOCODING_API);

  // Bank settings page doesn't care what card was added, but the buy flow needs to know
  onComplete = () => {
    this.setState(state => {
      this.props.onPaymentMethodUpdate?.(state.cardProps);
      return { verifyCardStepTwoVisible: false };
    });
  };
  closeModal = (
    name: keyof AddDebitCardFlowState,
    close: boolean,
    isManualAddressMode?: boolean,
    isBackButtonClickedOnDebitCardEntryScreen?: boolean
  ) => {
    if (close) {
      switch (name) {
        case "addBillingAddressManualVisible":
          isManualAddressMode
            ? track(EVENTS.ADD_DEBIT_CARD_ADDRESS.name, {
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.MODE]: "Manual",
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.STATUS]: "Closed",
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.IS_DEFAULT_ADDRESS_CHECKED]: "Unchecked",
              })
            : track(EVENTS.ADD_DEBIT_CARD_ADDRESS.name, {
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.MODE]: "Default",
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.STATUS]: "Closed",
                [EVENTS.ADD_DEBIT_CARD_ADDRESS.properties.IS_DEFAULT_ADDRESS_CHECKED]: "Checked",
              });

          break;
        case "addDebitCardVisible":
          track(EVENTS.ADD_DEBIT_CARD_INFO.name, {
            [EVENTS.ADD_DEBIT_CARD_INFO.properties.STATUS]: "Closed",
          });
          break;
        case "verifyCardStepOneVisible":
          track(EVENTS.VERIFY_CARD_STEP_ONE.name, {
            [EVENTS.VERIFY_CARD_STEP_ONE.properties.STATUS]: "Closed",
          });
          break;
        case "verifyCardStepTwoVisible":
          track(EVENTS.VERIFY_CARD_STEP_TWO.name, {
            [EVENTS.VERIFY_CARD_STEP_TWO.properties.STATUS]: "Closed",
          });
          break;
        case "transactionsSentVisible":
          track(EVENTS.TRANSACTIONS_SENT_MODAL.name, {
            [EVENTS.TRANSACTIONS_SENT_MODAL.properties.STATUS]: "Closed",
          });
          break;
      }
      if (this.isNewDebitCardFlowEnabled) {
        this.setState({ useDefaultAddress: true });
        this.setState({ isManualAddressMode: false });
      }
      this.props.onClose?.();
      if (!isBackButtonClickedOnDebitCardEntryScreen) {
        this.props.openAddPaymentMethodsModal?.();
        this.props.closeAddPaymentMethodsModal?.();
      }
    }
  };

  openDebitCardErrorModal = (debitCardErrorHeading: string, debitCardErrorDescription?: string) => {
    this.setState({ openDebitCardErrorModal: true, debitCardErrorHeading, debitCardErrorDescription });
  };

  closeDebitCardErrorModal = () => {
    this.setState({ openDebitCardErrorModal: false });
  };

  setCountryDetails = (country: string, countryFullName: string) => {
    this.setState({ country, countryFullName });
  };

  requireManualAddressEntry = (name: keyof AddDebitCardFlowState) =>
    !this.googleGeocodingActive && name === "addBillingAddressVisible";

  onToggle =
    (
      name: keyof AddDebitCardFlowState,
      close?: boolean,
      isManualAddressMode?: boolean,
      isBackButtonClickedOnDebitCardEntryScreen?: boolean
    ) =>
    () => {
      if (name === "verifyCardStepOneVisible" && close) {
        track(EVENTS.SKIP_MICRO_DEPOSIT.name);
      }

      if (name === "selectDebitCurrencyVisible" && !close) {
        const { name: eventName, properties } = EVENTS.GET_STARTED_DEBIT_CARD;
        track(eventName, { [properties.TRIGGER]: "Buy Flow" });
      }

      if (this.requireManualAddressEntry(name)) name = "selectCountryVisible";

      if (
        name === "addBillingAddressManualVisible" &&
        isManualAddressMode &&
        this.isNewDebitCardFlowEnabled &&
        !this.isCardAllowedForDerivativeAccount
      ) {
        this.setState({ isManualAddressMode: true });
      }
      if (name === "addBillingAddressManualVisible" && !isManualAddressMode && this.isNewDebitCardFlowEnabled) {
        this.setState({ useDefaultAddress: true });
        this.setState({ isManualAddressMode: false });
      }
      if (this.isNewDebitCardFlowEnabled && !close) {
        switch (name) {
          case "addDebitCardVisible":
            track(EVENTS.ADD_DEBIT_CARD_INFO.name, {
              [EVENTS.ADD_DEBIT_CARD_INFO.properties.STATUS]: "Open",
            });
            break;
          case "verifyCardStepOneVisible":
            track(EVENTS.VERIFY_CARD_STEP_ONE.name, {
              [EVENTS.VERIFY_CARD_STEP_ONE.properties.STATUS]: "Open",
            });
            break;
          case "verifyCardStepTwoVisible":
            track(EVENTS.VERIFY_CARD_STEP_TWO.name, {
              [EVENTS.VERIFY_CARD_STEP_TWO.properties.STATUS]: "Open",
            });
            break;
          case "transactionsSentVisible":
            track(EVENTS.TRANSACTIONS_SENT_MODAL.name, {
              [EVENTS.TRANSACTIONS_SENT_MODAL.properties.STATUS]: "Open",
            });
            break;
        }
      }
      this.toggleModal(name);
      this.closeModal(name, close, isManualAddressMode, isBackButtonClickedOnDebitCardEntryScreen);
    };

  toggleModal = (name: keyof AddDebitCardFlowState) => {
    if (this.requireManualAddressEntry(name) && !this.isNewDebitCardFlowEnabled) name = "selectCountryVisible";
    this.setState(state => ({
      ...initialModalState,
      [name]: !state[name],
    }));
  };

  verifyCard = (cardProps: DebitCardType, isExistingCard = true) => {
    const { canGenerateAuth, canVerifyAuth, id, currencies, minMicroDeposit, maxMicroDeposit } = cardProps;

    this.setState(state => ({
      ...initialModalState,
      cardProps,
      verifyCardStepOneVisible: canGenerateAuth && !canVerifyAuth,
      transactionsSentVisible: canVerifyAuth,
      tooManyAttemptsVisible: !canGenerateAuth && !canVerifyAuth,
      uuid: id,
      selectedCurrency: currencies[0],
      isExistingCard,
      minMicroDeposit,
      maxMicroDeposit,
    }));

    if (!minMicroDeposit || !maxMicroDeposit) {
      axios
        .get<DebitCardType>(jsRoutes.com.gemini.web.server.funding.controllers.CardsController.get(id).url)
        .then(res => {
          this.setState({ minMicroDeposit: res.data.minMicroDeposit, maxMicroDeposit: res.data.maxMicroDeposit });
        })
        .catch(err => {
          Sentry.captureException(err);
        });
    }
  };

  updateCountry = (country: string) => {
    this.setState({ country });
  };

  updateBillingAddress = ({ address1, address2, city, state, zip, country, countryFullName }: BillingAddress) => {
    const { name } = EVENTS.ADD_BILLING_ADDRESS;
    track(name);
    this.setState({
      address1,
      address2,
      city,
      state,
      zip,
      ...(country && { country: country.toLowerCase() }),
      countryFullName,
    });
  };

  onBack = () => {
    if (!this.isDerivativeDebitCardAdditionEnabled || !this.isDerivativeSubaccount) {
      this.onToggle(DebitCardModalState.addBillingAddressManualVisible, true, this.state.isManualAddressMode, true)();
    }
    this.props.openAddPaymentMethodsModal();
  };

  updateCardInfo = ({ cardHolderName, sessionToken }: DebitCardInfo) => {
    this.setState({ cardHolderName, sessionToken });
  };

  updateCurrency = (selectedCurrency: CurrencyShortNameFiat) => {
    this.setState({ selectedCurrency });
  };

  setUseDefaultAddress = (useDefaultAddress: boolean) => {
    this.setState({
      isManualAddressMode: !useDefaultAddress,
      ...this.initialAddressState,
      useDefaultAddress,
    });
  };

  setHandleDeviceDataResponse = (
    handleDeviceDataResponse: DeviceDataInitialisation & { unVerifiedTokenID: string }
  ) => {
    this.setState({ ...handleDeviceDataResponse });
  };

  setShowDeviceDataIframe = (showDeviceDataIframe: boolean) => this.setState({ showDeviceDataIframe });

  getInitialFormData = (isManualAddressMode: boolean, userDetails: UserAddressType, field: string) => {
    switch (field) {
      case "street":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.address1;
      case "apt":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.address2;
      case "city":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.city;
      case "state":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.state;
      case "zip":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.zip;
      case "country":
        return !isManualAddressMode ? userDetails?.address[field] : this.state.country;
    }
  };

  render() {
    const {
      supportedFiat,
      defaultFiat,
      onBack,
      settingsFlow,
      requestRefresh,
      intl,
      geminiEntity,
      defaultCardHolderName,
      userDetails,
    } = this.props;
    const {
      selectDebitCurrencyVisible,
      selectCountryVisible,
      addDebitCardVisible,
      addBillingAddressManualVisible,
      addBillingAddressVisible,
      verifyCardStepOneVisible,
      transactionsSentVisible,
      verifyCardStepTwoVisible,
      tooManyAttemptsVisible,
      showDebitCardSuccessModal,
      cardDeclinedVisible,
      selectedCurrency,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      uuid,
      maxMicroDeposit,
      minMicroDeposit,
      isExistingCard,
      useDefaultAddress,
      isManualAddressMode,
      selectExchangeAccountVisible,
      showChallengeFormIframe,
    } = this.state;

    return (
      <Fragment>
        {selectDebitCurrencyVisible && !this.isNewDebitCardFlowEnabled && (
          <SelectDebitCurrencyModal
            defaultCurrency={selectedCurrency}
            onToggle={this.onToggle}
            defaultFiat={defaultFiat}
            supportedFiat={supportedFiat}
            updateCurrency={this.updateCurrency}
            onBack={onBack}
          />
        )}
        {selectExchangeAccountVisible && (
          <SelectExchangeModal
            onOpen={this.state.selectExchangeAccountVisible}
            subaccounts={this.props.subaccounts}
            onToggle={this.onToggle}
            setSelectedSubaccountHashid={this.props.setSelectedSubaccountHashid}
            onBack={this.onBack}
            onClose={this.onToggle(DebitCardModalState.selectExchangeAccountVisible, true)}
          />
        )}
        {selectCountryVisible && !this.isNewDebitCardFlowEnabled && (
          <SelectCountryModal onToggle={this.onToggle} updateCountry={this.updateCountry} />
        )}
        {addBillingAddressVisible && (
          <AddBillingAddressModal onToggle={this.onToggle} updateBillingAddress={this.updateBillingAddress} />
        )}
        {addBillingAddressManualVisible && (
          <AddBillingAddressManualModal
            onToggle={this.onToggle}
            streetAddress={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.street)}
            apt={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.apt)}
            locality={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.city)}
            region={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.state)}
            postalCode={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.zip)}
            country={
              this.isNewDebitCardFlowEnabled
                ? this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.country)
                : country
            }
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            countryFullName={this.state.countryFullName}
            updateBillingAddress={this.updateBillingAddress}
            useDefaultAddress={useDefaultAddress}
            setUseDefaultAddress={this.setUseDefaultAddress}
            isManualAddressMode={isManualAddressMode}
            setCountryDetails={this.setCountryDetails}
            onBack={this.onBack}
            openAddPaymentMethodsModal={this.props.openAddPaymentMethodsModal || this.props.onClose}
          />
        )}
        {addDebitCardVisible && (
          <AddDebitCardModal
            defaultCardHolderName={defaultCardHolderName}
            address1={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.street)}
            address2={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.apt)}
            city={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.city)}
            state={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.state)}
            zip={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.zip)}
            country={this.getInitialFormData(isManualAddressMode, userDetails, INITIAL_ADDRESS_FIELDS.country)}
            selectedCurrency={selectedCurrency}
            updateCardInfo={this.updateCardInfo}
            handleVerifyCard={this.verifyCard}
            onToggle={this.onToggle}
            requestRefresh={requestRefresh}
            geminiEntity={geminiEntity}
            isManualAddressMode={isManualAddressMode}
            openDebitCardErrorModal={this.openDebitCardErrorModal}
            selectedSubaccountHashid={this.props.selectedSubaccountHashid}
            subaccountHashid={this.props.subaccountHashid}
            setHandleDeviceDataResponse={this.setHandleDeviceDataResponse}
            showDeviceDataIframe={this.state.showDeviceDataIframe}
          />
        )}
        {verifyCardStepOneVisible && (
          <VerifyYourCardStepOneModal
            onToggle={this.onToggle}
            isExistingCard={isExistingCard}
            minMicroDeposit={minMicroDeposit}
            maxMicroDeposit={maxMicroDeposit}
            uuid={uuid}
            requestRefresh={requestRefresh}
            openDebitCardErrorModal={this.openDebitCardErrorModal}
          />
        )}
        {transactionsSentVisible && <TransactionsSentModal onToggle={this.onToggle} requestRefresh={requestRefresh} />}
        {verifyCardStepTwoVisible && (
          <VerifyYourCardStepTwoModal
            onToggle={this.onToggle}
            onComplete={this.onComplete}
            minMicroDeposit={minMicroDeposit}
            maxMicroDeposit={maxMicroDeposit}
            uuid={uuid}
            selectedCurrency={selectedCurrency}
            settingsFlow={settingsFlow}
            requestRefresh={requestRefresh}
            openDebitCardErrorModal={this.openDebitCardErrorModal}
          />
        )}
        {cardDeclinedVisible && (
          <Modal
            isOpen
            onClose={() => this.toggleModal("cardDeclinedVisible")}
            title={intl.formatMessage({
              defaultMessage: "Something went wrong",
            })}
          >
            <p>
              {intl.formatMessage({
                defaultMessage: "Your card has been declined by the card issuer.",
              })}
            </p>
            <Button.Group>
              <Button.Primary
                onClick={() => this.toggleModal("cardDeclinedVisible")}
                data-testid="card-declined-modal"
                cta={intl.formatMessage({
                  defaultMessage: "Try Again",
                })}
              />
            </Button.Group>
          </Modal>
        )}
        {this.state.showDeviceDataIframe && (
          <DeviceData
            url={this.state.deviceDataURL}
            jwt={this.state.deviceDataJWT}
            bin={this.state.bin}
            handleSubmit={async (SessionId: string) => {
              try {
                const { displayName, authenticateResponse } = await handleAuthenticateTransaction(
                  SessionId,
                  this.state.unVerifiedTokenID,
                  this.state.internalReference,
                  this.state.country,
                  this.state.selectedCurrency
                );
                const { name, properties } = EVENTS.ADD_PAYMENT_3DS_AUTHENTICATION_SUCCESS;
                track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
                handleOpen3DSChallengeIframe(
                  displayName,
                  authenticateResponse,
                  this.onToggle,
                  this.openDebitCardErrorModal,
                  (authenticateResponse: AuthenticateRespone) => {
                    this.setState({ authenticateResponse });
                  }
                );
              } catch (error) {
                const { name, properties } = EVENTS.ADD_PAYMENT_3DS_AUTHENTICATION_FAILED;
                track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
                this.setShowDeviceDataIframe(false);
                handle3DSErrorState(error, this.openDebitCardErrorModal);
              }
            }}
          />
        )}
        {showChallengeFormIframe && (
          <Modal.MultiStep isOpen>
            <Flex alignItems="center" justifyContent="center">
              <ChallengeForm
                jwt={this.state.authenticateResponse?.challengeRequest?.jwt}
                md={this.state.authenticateResponse?.MD}
                url={this.state.authenticateResponse?.challengeRequest?.url}
                handleSubmit={() => {
                  const callbacks: PostChallengeFormCallbacksType = {
                    onToggle: this.onToggle,
                    verifyCard: this.verifyCard,
                    openDebitCardErrorModal: this.openDebitCardErrorModal,
                    setShowDeviceDataIframe: this.setShowDeviceDataIframe,
                  };
                  processChallengeFormSubmit(this.state, this.setState, this.props, callbacks);
                }}
              />
            </Flex>
          </Modal.MultiStep>
        )}
        {this.isNewDebitCardFlowEnabled && this.state.openDebitCardErrorModal ? (
          <DebitCardErrorModal
            isOpen={this.state.openDebitCardErrorModal}
            onClose={() => {
              this.closeDebitCardErrorModal();
              requestRefresh();
            }}
            errorHeading={this.state.debitCardErrorHeading}
            errorDescription={this.state.debitCardErrorDescription}
          />
        ) : null}
        {this.isAddPaymentMethodsRevamped && showDebitCardSuccessModal && (
          <AddPaymentMethodSuccessModal
            isOpen
            onClose={() => {
              this.toggleModal("showDebitCardSuccessModal");
              requestRefresh();
            }}
            paymentMethodType={AddPaymentMethodType.DEBIT}
            isSettingsOrOnboarding={true}
            scope={this.props.scope}
            openSelectPaymentMethodModal={this.props.openSelectPaymentMethodModal}
            onSuccessCallback={this.props.onSuccessCallback}
          />
        )}
        {tooManyAttemptsVisible && (
          <Modal
            isOpen
            onClose={() => this.toggleModal("tooManyAttemptsVisible")}
            title={intl.formatMessage({
              defaultMessage: "Unable to verify",
            })}
          >
            <p>
              {intl.formatMessage({
                defaultMessage:
                  "You have exceeded verification attempts for your account today. Please try again tomorrow.",
              })}
            </p>
            <Button.Group>
              <Button.Primary
                onClick={() => this.toggleModal("tooManyAttemptsVisible")}
                data-testid="close-too-many-attempts-modal"
                cta={intl.formatMessage({
                  defaultMessage: "Close",
                })}
              />
            </Button.Group>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withIntl(AddDebitCardFlow);
