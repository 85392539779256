import { Actions, BorderNode, DockLocation, Model, TabSetNode } from "flexlayout-react";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { isMenuTab, MenuTabs, TabSet } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/constants";
import { pairLayoutTabs } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/model";

export const splitOrderbookAndMarketTrades = (intl: IntlShape, model: Model) => {
  const { EXCHANGE_ACTIVITY, ORDER_BOOK } = pairLayoutTabs(intl);

  model.doAction(Actions.deleteTab(EXCHANGE_ACTIVITY.id));
  const exchangeActivityNode = model.doAction(
    Actions.addNode(EXCHANGE_ACTIVITY, TabSet.MARKET_AND_ORDERBOOK, DockLocation.RIGHT, -1)
  );
  model.doAction(Actions.updateNodeAttributes(exchangeActivityNode.getParent().getId(), { id: TabSet.MARKET_TRADES }));

  model.doAction(Actions.deleteTab(ORDER_BOOK.id));
  const orderbookNode = model.doAction(Actions.addNode(ORDER_BOOK, TabSet.MARKET_TRADES, DockLocation.LEFT, -1));
  model.doAction(Actions.updateNodeAttributes(orderbookNode.getParent().getId(), { id: TabSet.ORDERBOOK }));
};

export const unifyOrderbookAndMarketTrades = (intl: IntlShape, model: Model) => {
  const { ORDER_BOOK } = pairLayoutTabs(intl);
  model.doAction(Actions.moveNode(ORDER_BOOK.id, TabSet.MARKET_TRADES, DockLocation.CENTER, 0));
  model.doAction(
    Actions.updateNodeAttributes(TabSet.MARKET_TRADES, { id: TabSet.MARKET_AND_ORDERBOOK, weight: 34, width: 360 })
  );
};

export const getActiveMenuTabId = (tabSetNode: TabSetNode | BorderNode) => {
  let activeId;
  tabSetNode.getChildren().forEach(tab => {
    const tabId = tab.getId();
    if (tab.isVisible()) {
      if (isMenuTab(tabId) && MenuTabs.indexOf(tabId) !== -1) {
        activeId = tabId;
      }
    }
  });
  return activeId;
};

const CUSTOM_LAYOUTS_OVERRIDE = "CUSTOM_LAYOUTS_OVERRIDE";
const CUSTOM_DUAL_LAYOUTS_OVERRIDE = "CUSTOM_DUAL_LAYOUTS_OVERRIDE";

export const areCustomLayoutsEnabled = () => {
  const override = Cookies.get(CUSTOM_LAYOUTS_OVERRIDE);
  if (override === "enabled" || override === "disabled") {
    return override === "enabled" ? true : false;
  }

  return optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_LAYOUTS_CUSTOMIZATION_ENABLED);
};

export const areCustomDualLayoutsEnabled = () => {
  const override = Cookies.get(CUSTOM_DUAL_LAYOUTS_OVERRIDE);
  if (override === "enabled" || override === "disabled") {
    return override === "enabled" ? true : false;
  }

  return optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DUAL_LAYOUTS_CUSTOMIZATION_ENABLED);
};
