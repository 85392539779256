import { Fragment, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { addHours, format, startOfHour } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useEffectOnce } from "react-use";
import { getQuantityCurrencyDetail, isCurrency, isFiatCurrency } from "@gemini-common/scripts/constants/currencies";
import { track, trackBingEvent, trackTikTokEvent } from "@gemini-ui/analytics";
import {
  BingEvents,
  BingVariableRevenueProperty,
  EVENTS,
  SNAPCHAT_PROPERTIES,
  TikTokEvents,
} from "@gemini-ui/analytics/constants/events";
import { snapchatTrack, snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import { useFeatureFlag } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Money } from "@gemini-ui/components/Money";
import { usePayPalDataCollector } from "@gemini-ui/components/PayPalModal/utils";
import { LoadingErrorSection } from "@gemini-ui/components/Services/LoadingErrorSection";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { FeatureFlag } from "@gemini-ui/constants/templateProps/featureFlags";
import { usePageData, usePageRefresh } from "@gemini-ui/contexts";
import { Button, Card, Flex, List, ListItem, Modal, SkeletonLoader, Text } from "@gemini-ui/design-system";
import { RateDetailsSummary } from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit/RateDetailsSummary";
import SummaryLineItem from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit/SummaryLineItem";
import { ConfirmButton } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit/styles";
import { ModalTitle } from "@gemini-ui/pages/Earn/Deposit/styles";
import { DepositModalView, RecurringDepositState } from "@gemini-ui/pages/Earn/Deposit/types";
import { getDefaultPaymentMethod, QUOTE_LOADING_PLACEHOLDER_TEXT } from "@gemini-ui/pages/Earn/Deposit/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowBuyDepositType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import Disclaimer from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/Disclaimer";
import {
  monthlyScheduleItems,
  twiceMonthlyScheduleItems,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/RecurringBuyDropdowns";
import {
  getQuoteError,
  getStartOnDate,
  recurringOrderMessage,
  trackTrade,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import {
  Action,
  BuyingFrequency,
  EMPTY_UUID,
  GENERIC_3DS_ERROR,
  OrderQuote,
  TrackTrade,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import PaymentMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod";
import { getPaymentMethodType } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import ChallengeForm from "@gemini-ui/pages/RetailTrade/PaymentMethod/WorldPayIframe/ChallengeForm";
import DeviceData from "@gemini-ui/pages/RetailTrade/PaymentMethod/WorldPayIframe/DeviceData";
import { UseAccountBalances } from "@gemini-ui/services/account/balances";
import axios, { AxiosError } from "@gemini-ui/services/axios";
import { useRecurringOrder } from "@gemini-ui/services/retail/recurringOrder";
import { useRecurringOrderData } from "@gemini-ui/services/retail/recurringOrderData";
import { StakingDepositMethod } from "@gemini-ui/services/staking/constants";
import { useStakingDeposit } from "@gemini-ui/services/staking/stakingDeposit";
import { useStakingQuote } from "@gemini-ui/services/staking/stakingQuote";
import { UsePaymentData } from "@gemini-ui/services/transfer/types";
import { DateFormats, TimeFormats } from "@gemini-ui/utils/dateTimeFormats";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  selectedPaymentMethod?: TradePaymentMethodType;
  setSelectedPaymentMethod?: (paymentMethod: TradePaymentMethodType) => void;
  recurringState?: RecurringDepositState;
  setRecurringState?: (state: RecurringDepositState) => void;
  paymentMethodData: UsePaymentData;
  balanceData: UseAccountBalances;
}

const ReviewBuyDeposit = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  recurringState,
  setRecurringState,
  paymentMethodData,
  balanceData,
}: Props) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();

  const { loading: isLoadingQuote, data: orderQuote, fetchQuote } = useStakingQuote<OrderQuote>();
  const { loading: isSubmittingDeposit, submitDeposit } = useStakingDeposit();
  const { loading: isLoadingRecurringData, fetchRecurringOrderData } = useRecurringOrderData();
  const { loading: isSubmittingRecurringDeposit, submitRecurringOrder } = useRecurringOrder();

  const isSubmitting = isSubmittingDeposit || isLoadingRecurringData || isSubmittingRecurringDeposit;

  const { requestRefresh } = usePageRefresh();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const {
    currency,
    provider,
    depositStatus,
    setQuote: setGrowBuyContextQuote,
    setDepositError,
    setModalView,
  } = useGrowBuy();
  const { amount, recurringFrequency, schedule, source } = depositStatus as GrowBuyDepositType<OrderQuote>;

  const { isLoading: isLoadingDataCollector, getDeviceData } = usePayPalDataCollector({
    shouldLoad:
      selectedPaymentMethod &&
      selectedPaymentMethod !== AccountType.BALANCE &&
      selectedPaymentMethod.paymentMethodType === PaymentMethodType.PAYPAL,
  });

  const [is3dsCheckActive, setIs3dsCheckActive] = useState(false);
  const [showDeviceDataIframe, setShowDeviceDataIframe] = useState(false);
  const [showChallengeFormIframe, setShowChallengeFormIframe] = useState(false);
  const [dataDeviceResponse, setDataDeviceResponse] = useState(null);
  const [authenticateResponse, setAuthenticateResponse] = useState(null);
  const [internalReference, setInternalReference] = useState(null);

  const InternationalDebitCardEnabled = useFeatureFlag(FeatureFlag.InternationalDebitCardEnabled);
  const Card3DSEnabled = useFeatureFlag(FeatureFlag.Card3DSEnabled);

  const quote = orderQuote?.quote;
  const isAwaitingQuote = isLoadingQuote || !Boolean(orderQuote);

  const isStaking = provider?.providerType === GrowProviderType.POOLED_STAKING;

  const getBuyAndStakeQuote = async () => {
    if (!amount) return;

    let defaultPaymentMethod = null;

    if (!selectedPaymentMethod) {
      defaultPaymentMethod = getDefaultPaymentMethod(
        paymentMethodData,
        defaultFiat,
        true,
        balanceData.data ?? {},
        amount,
        BuyingFrequency.Once,
        true
      );

      setSelectedPaymentMethod(defaultPaymentMethod);
    }

    const currentPaymentMethod = selectedPaymentMethod ?? defaultPaymentMethod;
    const paymentMethodMetadata = {
      paymentMethodUuid: currentPaymentMethod !== AccountType.BALANCE ? currentPaymentMethod?.id : undefined,
      paymentMethodType: getPaymentMethodType(currentPaymentMethod),
    };

    try {
      const quote = await fetchQuote(StakingDepositMethod.BUY_AND_STAKE, {
        to: currency,
        from: defaultFiat,
        totalSpend: amount,
        paymentMethodUuid: paymentMethodMetadata.paymentMethodUuid,
        paymentMethodType: paymentMethodMetadata.paymentMethodType,
        isRecurringOrder: schedule !== BuyingFrequency.Once,
        earnProviderId: provider?.id,
      });

      const { weekly, twiceMonthly, monthly } = recurringFrequency;
      const triggerHour = format(startOfHour(addHours(new Date(), 1)), TimeFormats.Hour24Minute);

      const startOn = getStartOnDate(triggerHour, {
        schedule: schedule,
        weeklyFrequency: weekly,
        twiceMonthlyFrequency: twiceMonthly,
        monthlyFrequency: monthly,
      });

      const utcTime = utcToZonedTime(startOn, "UTC");

      setGrowBuyContextQuote(quote);
      setRecurringState({ triggerHour, startOn, utcTime });
    } catch (err) {
      setDepositError(getQuoteError(err, intl));
      setModalView(DepositModalView.PLACE_DEPOSIT);
    }
  };

  useEffect(() => {
    if (!orderQuote && !paymentMethodData.isLoading && !balanceData.loading) {
      getBuyAndStakeQuote();
    }
    // `getBuyAndStakeQuote` contains context updates that will cause an infinite loop if we use a dependency array
    // We do not need to rebuild it on every render, so we will just ignore the exhaustive-deps here instead.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderQuote, paymentMethodData.isLoading, balanceData.loading]);

  useEffectOnce(() => {
    track(EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.name, {
      [EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.properties.CURRENCY]: currency,
      [EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.properties.PROVIDER_TYPE]: provider?.providerType,
      [EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.properties.AMOUNT]: amount,
      [EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.properties.FUNDING_SOURCE]: source,
      [EVENTS.VIEW_REVIEW_STAKE_DEPOSIT.properties.SCHEDULE]: schedule,
    });
  });

  const handleDeviceDataInitialization = () => {
    if (selectedPaymentMethod === AccountType.BALANCE) return;
    setIs3dsCheckActive(true);
    axios
      .post(
        jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.deviceDataInitialization(
          selectedPaymentMethod.id
        ).url,
        {
          amount: {
            amount: quote?.totalSpend.value,
            currency: quote?.totalSpend.currency,
          },
        }
      )
      .then(res => {
        setDataDeviceResponse(res.data);
        setInternalReference(res.data?.internalReference);
        setShowDeviceDataIframe(true);
      })
      .catch(err => {
        setIs3dsCheckActive(false);
        showAlert({ type: AlertTypes.ERROR, message: getError(err, GENERIC_3DS_ERROR) });
      });
  };

  const handleAuthenticateTransaction = (SessionId: string) => {
    if (selectedPaymentMethod === AccountType.BALANCE) return;
    axios
      .post(
        jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.authenticateTransaction(
          selectedPaymentMethod.id
        ).url,
        {
          internalReference,
          collectionReference: SessionId,
        }
      )
      .then(res => {
        setIs3dsCheckActive(false);
        switch (res.data?.challengeType?.displayName) {
          case "3DS":
            setAuthenticateResponse(res.data);
            setShowChallengeFormIframe(true);
            break;
          case "NotRequired":
            handleSubmit();
            break;
        }
      })
      .catch(err => {
        setIs3dsCheckActive(false);
        showAlert({ type: AlertTypes.ERROR, message: getError(err, GENERIC_3DS_ERROR) });
      });
  };

  /* istanbul ignore next */
  const log3DSactivity = res => {
    if (selectedPaymentMethod === AccountType.BALANCE) return;
    axios.post(
      jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.log3DSactivity(
        selectedPaymentMethod.id
      ).url,
      {
        internalReference: res.data.internalReference || internalReference,
        amount,
        challengeStatus: res.status,
        challengePayload: JSON.stringify(res.data),
      }
    );
  };

  const handleVerifyChallenge = () => {
    if (selectedPaymentMethod === AccountType.BALANCE) return;
    axios
      .post(
        jsRoutes.com.gemini.web.server.funding.controllers.VerificationsController.verifyChallenge(
          selectedPaymentMethod.id
        ).url,
        {
          internalReference,
        }
      )
      .then(res => {
        setIs3dsCheckActive(false);
        setShowChallengeFormIframe(false);
        setShowDeviceDataIframe(false);
        handleSubmit();
        return res;
      })
      .catch(err => {
        setIs3dsCheckActive(false);
        showAlert({ type: AlertTypes.ERROR, message: getError(err, GENERIC_3DS_ERROR) });
        return err.response;
      })
      .then(res => log3DSactivity(res));
  };

  const onError = (err: AxiosError) => {
    showAlert({ type: AlertTypes.ERROR, message: getError(err) });
  };

  const handleSubmit = async () => {
    if (!Boolean(quote)) {
      showAlert({
        type: AlertTypes.ERROR,
        message: intl.formatMessage({ defaultMessage: "Something went wrong, please try again." }),
      });
      return;
    }

    const paymentMethodUuid = selectedPaymentMethod !== AccountType.BALANCE ? selectedPaymentMethod?.id : undefined;
    const paymentMethodType = getPaymentMethodType(selectedPaymentMethod);
    const assetName = getQuantityCurrencyDetail(currency + defaultFiat).name;
    let deviceData;
    if (paymentMethodType === AccountType.PAYPAL) {
      deviceData = await getDeviceData();
    }
    if (schedule === BuyingFrequency.Once) {
      const formData = {
        ...quote?.formData,
        paymentMethodType: paymentMethodType,
        paymentMethodUuid: paymentMethodUuid,
        earnProviderId: provider?.id,
        ...(internalReference && { internalReference }),
        ...(Boolean(deviceData) && {
          payPalData: {
            deviceData,
          },
        }),
      };

      submitDeposit(StakingDepositMethod.BUY_AND_STAKE, formData)
        .then(_ => {
          requestRefresh();
          setModalView(DepositModalView.SUCCESS);

          const { quantity, price, fee } = quote?.formData;

          const tradeProperties: TrackTrade = {
            schedule: BuyingFrequency.Once,
            orderType: "Instant",
            tradingPair: currency + defaultFiat,
            side: "buy",
            quantity,
            price,
            fee,
            total: quote?.totalSpend.value,
            quickBuy: false,
            usedSuggestedAmounts: false,
            paymentMethod: paymentMethodType,
            defaultFiat: defaultFiat,
            assetName,
            assetEarnApy: quote?.annualPercentYield ? `${quote?.annualPercentYield}% APY` : null,
            currency: formData.priceCurrency,
            value: quote?.approxNotional.value,
          };
          trackOrderConfirmation(tradeProperties);
        })
        .catch(onError);
    } else {
      fetchRecurringOrderData()
        .then(response => {
          const { weekly, twiceMonthly, monthly } = recurringFrequency;

          const formData: any = {
            schedule: schedule.toLowerCase(),
            startOn: format(recurringState?.utcTime, DateFormats.YearMonthDay),
            triggerHour: format(recurringState?.utcTime, TimeFormats.Hour24Minute),
            notional: quote?.totalSpend.value,
            tradingPair: currency + defaultFiat,
            executionType: "InstantOrder",
            paymentMethodType: paymentMethodType,
            paymentMethodUuid: paymentMethodUuid,
            bankAccountId: response.achInfo ? response.achInfo.uuid : EMPTY_UUID,
            earnProviderId: provider?.id,
            ...(internalReference && { internalReference }),
            ...(schedule === BuyingFrequency.Monthly && { dates: monthly }),
            ...(schedule === BuyingFrequency.Biweekly && { dates: twiceMonthly }),
            ...(Boolean(deviceData) && {
              payPalData: {
                deviceData,
              },
            }),
          };

          submitRecurringOrder(formData)
            .then(_ => {
              requestRefresh();
              setModalView(DepositModalView.SUCCESS);

              const tradeProperties: TrackTrade = {
                schedule: schedule,
                orderType: "Recurring",
                tradingPair: currency + defaultFiat,
                side: "buy",
                total: quote?.totalSpend.value,
                paymentMethod: paymentMethodType,
                dayOfWeek: weekly,
                dates: formData.dates,
                triggerHour: recurringState?.triggerHour,
                defaultFiat: defaultFiat,
                assetName,
                assetEarnApy: quote?.annualPercentYield ? `${quote?.annualPercentYield}% APY` : null,
                currency: formData.priceCurrency,
                value: quote?.approxNotional.value,
              };
              trackOrderConfirmation(tradeProperties);
            })
            .catch(onError);
        })
        .catch(onError);
    }
  };

  const trackOrderConfirmation = (tradeProperties: TrackTrade): void => {
    try {
      const strTrackAmount = parseFloat(amount).toFixed(2);

      track(EVENTS.STAKE_DEPOSIT.name, {
        [EVENTS.STAKE_DEPOSIT.properties.CURRENCY]: currency,
        [EVENTS.STAKE_DEPOSIT.properties.PROVIDER_TYPE]: provider?.providerType,
        [EVENTS.STAKE_DEPOSIT.properties.AMOUNT]: strTrackAmount,
        [EVENTS.STAKE_DEPOSIT.properties.FUNDING_SOURCE]: source,
        [EVENTS.STAKE_DEPOSIT.properties.SCHEDULE]: schedule,
      });
      snapchatTrack(snapEvents.addCart, {
        [SNAPCHAT_PROPERTIES.CURRENCY]: currency,
        [SNAPCHAT_PROPERTIES.PRICE]: amount,
      });
      trackTikTokEvent(TikTokEvents.EarnDeposit);

      // Bing UETG tracking will only accept ISO currency codes
      if (isFiatCurrency(currency))
        trackBingEvent(BingEvents.EarnDeposit, {
          [BingVariableRevenueProperty.RevenueValue]: strTrackAmount,
          [BingVariableRevenueProperty.Currency]: currency,
        });

      trackTrade(tradeProperties);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Fragment>
      <ModalTitle>
        {provider?.providerType === GrowProviderType.POOLED_STAKING
          ? intl.formatMessage({ defaultMessage: "Buy & stake confirmation" })
          : intl.formatMessage({ defaultMessage: "Confirm purchase" })}
      </ModalTitle>

      <Flex flexDirection="column" mt={2}>
        <Text.Body bold size="sm" mb={1}>
          {intl.formatMessage({ defaultMessage: "Payment method" })}
        </Text.Body>
        {paymentMethodData.isLoading || balanceData.loading ? (
          <SkeletonLoader height="60px" width="100%" radiusSize="md" />
        ) : paymentMethodData.hasError ? (
          <LoadingErrorSection
            refetchData={() => paymentMethodData.refetchPaymentData(true)}
            errorMessage={intl.formatMessage({
              defaultMessage: "An error occurred while loading payment method data. Please try again.",
            })}
          />
        ) : (
          <PaymentMethod
            selectedPaymentMethod={selectedPaymentMethod}
            balances={balanceData.data}
            defaultFiat={defaultFiat}
            orderQuote={orderQuote}
            onClick={() => {}}
            onToggle={() => {
              // Make sure we have a quote before we toggle
              if (isAwaitingQuote) return;
              setModalView(DepositModalView.SELECT_PAYMENT_METHOD);
            }}
            paymentMethods={paymentMethodData.paymentMethods}
            limits={paymentMethodData.limits}
          />
        )}
      </Flex>

      {quote && (
        <Card variant="filled" padding="none">
          <List>
            {schedule !== BuyingFrequency.Once && (
              <SummaryLineItem
                label={intl.formatMessage({ defaultMessage: "Frequency", description: "Buying frequency" })}
                text={
                  isAwaitingQuote ? (
                    QUOTE_LOADING_PLACEHOLDER_TEXT
                  ) : (
                    <Fragment>
                      {intl.formatMessage(
                        defineMessage({
                          defaultMessage:
                            "<MoneyQuoteApproxUsd></MoneyQuoteApproxUsd> {scheduleValueToLocaleLowerCase}",
                          description: "e.g. Recurring purchase of $100 per month",
                        }),
                        {
                          MoneyQuoteApproxUsd: () => <Money {...quote?.approxUsd} hideTrailingSign />,
                          scheduleValueToLocaleLowerCase: schedule?.toLocaleLowerCase(),
                        }
                      )}
                    </Fragment>
                  )
                }
                subText={
                  isAwaitingQuote
                    ? QUOTE_LOADING_PLACEHOLDER_TEXT
                    : recurringOrderMessage({
                        schedule: schedule,
                        triggerHour: recurringState?.triggerHour,
                        startOn: recurringState?.startOn,
                        twiceMonthlyFrequency: twiceMonthlyScheduleItems(intl).find(
                          v => v.value[0] === recurringFrequency.twiceMonthly?.[0]
                        ).label,
                        monthlyFrequency: monthlyScheduleItems(intl).find(
                          v => v.value[0] === recurringFrequency.monthly?.[0]
                        ).label,
                        intl: intl,
                      })
                }
              />
            )}

            <SummaryLineItem
              label={intl.formatMessage({ defaultMessage: "Destination", description: "Deposited funds destination" })}
              text={intl.formatMessage(
                defineMessage({
                  defaultMessage: "{destination, select, Staking {Staking balance} Earn {Earn balance} other {}}",
                }),
                {
                  destination: provider?.providerType,
                }
              )}
            />

            <RateDetailsSummary
              currency={currency}
              interestTiers={provider?.annualInterestYieldTiers}
              isAwaitingQuote={isAwaitingQuote}
              quoteInterestRate={quote?.annualPercentYield}
            />

            <SummaryLineItem
              label={intl.formatMessage({ defaultMessage: "Order quantity" })}
              text={isAwaitingQuote ? QUOTE_LOADING_PLACEHOLDER_TEXT : <Money {...quote?.approxUsd} hideTrailingSign />}
              subText={
                !isAwaitingQuote && (
                  <Fragment>
                    {isCurrency.GUSD(currency) &&
                      intl.formatMessage({ defaultMessage: "equiv.", description: "abbreviation for equivalent" })}{" "}
                    <Money {...quote?.quantity} removeTrailingZeros />
                  </Fragment>
                )
              }
            />
            <SummaryLineItem
              label={intl.formatMessage({ defaultMessage: "Purchase fee" })}
              text={isAwaitingQuote ? QUOTE_LOADING_PLACEHOLDER_TEXT : <Money {...quote?.fee} hideTrailingSign />}
            />

            {[AccountType.DEBIT_CARD, AccountType.PAYPAL].includes(getPaymentMethodType(selectedPaymentMethod)) && (
              <SummaryLineItem
                label={
                  getPaymentMethodType(selectedPaymentMethod) === AccountType.DEBIT_CARD
                    ? intl.formatMessage({
                        defaultMessage: "Platform fee",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Processing fee",
                      })
                }
                text={
                  isAwaitingQuote ? QUOTE_LOADING_PLACEHOLDER_TEXT : <Money {...quote?.depositFee} hideTrailingSign />
                }
              />
            )}

            <SummaryLineItem
              label={intl.formatMessage({ defaultMessage: "Total", description: "Total amount to be deposited" })}
              text={
                isAwaitingQuote ? QUOTE_LOADING_PLACEHOLDER_TEXT : <Money {...quote?.totalSpend} hideTrailingSign />
              }
              textTestId={testIds.deposit.reviewBuyDeposit.quoteNotionalAmount}
            />

            <ListItem size="dense" padding="sm">
              <Disclaimer
                paymentMethod={selectedPaymentMethod}
                action={Action.BUY}
                frequency={schedule}
                defaultFiat={defaultFiat}
                tradingPair={currency + defaultFiat}
                orderQuote={orderQuote}
                isEarn
                earnProvider={provider}
              />
            </ListItem>
          </List>
        </Card>
      )}

      <Button.Group type="action">
        <ConfirmButton
          data-testid={testIds.deposit.reviewBuyDeposit.completeButton}
          onClick={() =>
            InternationalDebitCardEnabled &&
            Card3DSEnabled &&
            selectedPaymentMethod !== AccountType.BALANCE &&
            selectedPaymentMethod.paymentMethodType === PaymentMethodType.DEBIT_CARD
              ? handleDeviceDataInitialization()
              : handleSubmit()
          }
          disabled={isAwaitingQuote || !selectedPaymentMethod}
          loading={isAwaitingQuote || isSubmitting || is3dsCheckActive || isLoadingDataCollector}
          size="lg"
          type="submit"
          cta={
            !isAwaitingQuote &&
            intl.formatMessage(
              defineMessage({
                defaultMessage: "{isStaking, select, true {Stake} other {Confirm}}",
                description: "Complete your deposit",
              }),
              {
                isStaking,
              }
            )
          }
        />
      </Button.Group>

      {showDeviceDataIframe && (
        <DeviceData
          url={dataDeviceResponse?.form?.url}
          jwt={dataDeviceResponse?.form?.jwt}
          bin={dataDeviceResponse?.form?.bin}
          handleSubmit={handleAuthenticateTransaction}
        />
      )}
      <Modal isOpen={showChallengeFormIframe}>
        <div css={{ textAlign: "center" }}>
          <ChallengeForm
            jwt={authenticateResponse?.challengeRequest?.jwt}
            md={authenticateResponse?.MD}
            url={authenticateResponse?.challengeRequest?.url}
            handleSubmit={handleVerifyChallenge}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default ReviewBuyDeposit;
