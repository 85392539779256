import { Application, ApplicationCreditPullEnum, ApplicationStatusState } from "@gemini-ui/client/credit";
import { Alert } from "@gemini-ui/components/GlobalAlert/constants";
import { InviteEvent } from "@gemini-ui/pages/Credit/AuthUser/AcceptInvite/constants";
import { ClientViews, UserEvent } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";

export const MAX_CARD_NAME_LENGTH = 32;
export const CREDIT_MIN = 1000;
export const CREDIT_MAX = 25000;

export const CREDIT_APP_LOGIN = `${CC_PATH.APPLICATION}/login`;

export const FINAL_COPY = {
  DIDNT_PREQUALIFY: "Sorry, you didn't pre-qualify",
  ADVERSE_NOTICE: "An adverse action notice will be sent to the email on file.",
  REPORTED_DECEASED: "Credit bureau reported you deceased",
  FOLLOW_UP: "Please follow up with the credit bureau.",
  NOT_APPROVED: "Sorry, you were not approved",
  FILE_FROZEN: "Your credit file is frozen",
  NEED_MORE_INFO: "We need some more information",
  NEED_MORE_INFO_TO_VERIFY: "We need some more information to verify your identity",
  CS_REP_CALL_SPECIFIC:
    "A customer service representative will be calling you within 3 business days from (866)-572-8785 to verify your application. We will make 5 attempts to contact you.",
  CS_REP_EMAIL: "A customer service representative will be reaching out to the email on file.",
  PROBLEMS_ON_OUR_END: "We're having problems on our end",
  WORKING_TO_FIX_THIS:
    "We are working to fix this and will reach out via email to let you know when your submission has been processed.",
  APPLIED_RECENTLY: "It seems you've applied recently",
  ONCE_EVERY_THIRTY_DAYS: "You may only apply once every thirty days.",
  DETAILS_OF_DECISION: "The details of our decision will be sent to the email address provided on the application.",
  ON_WAITLIST: "Sorry, you're still on the waitlist",
  COME_BACK: "Please come back once you have received our email.",
  INELIGIBLE_TO_APPLY: "You're ineligible to apply at this time",
  ONLY_US_USERS: "The Gemini Credit Card is currently only available to US-based users.",
  THANKS_FOR_YOUR_INTEREST: "Thank you for your interest in the Gemini Credit Card™!",
  MAX_SIGN_UPS: "We have reached our maximum number of sign-ups for today but please check back tomorrow to apply.",
  PAUSED_APPLICATIONS:
    "We have temporarily paused new card applications to further improve the experience. Please check back with us in a few days.",
  TRY_AGAIN_LATER: "Please try again later.",
  ENSURE_ACCOUNT_VERIFIED: "Please ensure your account is verified and in good standing in order to apply.",
  ONFIDO_VERIFICATION_FAILED_TITLE: "We can't prequalify you right now",
  ONFIDO_VERIFICATION_FAILED_SUBTITLE:
    "We'll send an email with details on this decision. Have a look and reapply when you feel the time is right again.",
  CONTACT_CREDIT_BUREAU: "Please contact Experian and Clarity to unfreeze your file and continue your application.",
  WATCH_EMAIL:
    "Your application is being reviewed. Please watch your email over the next few days for instructions on how to complete your application.",
  PREVIOUSLY_DECLINED: "Sorry, you're unable to start an application at this time.",
};

export type FormValues = {
  g_recaptcha_response_v2?: string;
  g_recaptcha_response_v3?: string;
  creditPull?: ApplicationCreditPullEnum;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  street?: string;
  apt?: string;
  city?: string;
  state?: string;
  zip?: string;
  dob?: string;
  ssn?: string;
  ssnLastFour?: string;
  newAppTerms?: boolean;
  softPullTerms?: boolean;
  creditReportTerms?: boolean;
  hardPullTerms?: boolean;
  grossAnnualIncome?: string;
  password?: string;
  invitationId?: string;
  socureId?: string;
};

export interface UserData {
  appData: Application;
  appId: string;
  isLoggedIn: boolean;
  offerId: string;
  isNewUser: boolean;
  statusHistory: ApplicationStatusState[];
  socureId: string;
  shouldSkipDocUpload: boolean;
  isPhase1Enabled: boolean;
}

export type Event = {
  type: ClientViews;
  shouldTriggerAAN?: boolean;
  shouldSkipDocUpload?: boolean;
};

export type XStateEvent = Partial<UserData> & Event;

export type XStateSend = (event: Event | XStateEvent | InviteEvent | UserEvent) => void;

export interface MachineProps {
  send: XStateSend;
  user: UserData;
  event?: XStateEvent;
}

export const FIELD_NAMES: Record<keyof FormValues, keyof FormValues> = {
  g_recaptcha_response_v2: "g_recaptcha_response_v2",
  g_recaptcha_response_v3: "g_recaptcha_response_v3",
  creditPull: "creditPull",
  firstName: "firstName",
  middleName: "middleName",
  lastName: "lastName",
  phone: "phone",
  email: "email",
  street: "street",
  apt: "apt",
  city: "city",
  state: "state",
  zip: "zip",
  dob: "dob",
  ssn: "ssn",
  ssnLastFour: "ssnLastFour",
  newAppTerms: "newAppTerms",
  softPullTerms: "softPullTerms",
  creditReportTerms: "creditReportTerms",
  hardPullTerms: "hardPullTerms",
  grossAnnualIncome: "grossAnnualIncome",
  password: "password",
  invitationId: "invitationId",
  socureId: "socureId",
};

export type ApplicationProgress = {
  value: number;
  max: number;
  step: React.ReactNode;
};

export interface OnboardingComponentProps extends Omit<MachineProps, "event"> {
  currentState?: ClientViews;
  containerRef?: React.MutableRefObject<HTMLDivElement>;
}

export interface HandleSubmit {
  send: XStateSend;
  values: FormValues;
  showAlert?: (alert: Alert) => void;
}

export const NEURO_IDS = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  dob: "dob",
  phone: "phone",
  address: "address",
  zip: "zip",
  city: "city",
  income: "income",
};
