import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";
import { GeminiPublicKeyCredentialRequestOptions } from "@gemini-ui/pages/Authy/constants";
import {
  decodeAssertionOpts,
  encodeAssertion,
  sendCredentialAssertion,
  sendCredentialAssertionSignin,
} from "@gemini-ui/pages/settings/SecuritySettings/TwoFactorSettings/webAuthn";
import axios, { AxiosError } from "@gemini-ui/services/axios";

type PasskeyAuthTypes = {
  redirect: string;
  onSuccess?: (data: { redirect: string }) => void;
  onError?: (e: AxiosError<{ error: string }> | DOMException) => void;
  email?: string;
  authSignedRequest?: string;
  publicKeyCredentialRequestOptions?: GeminiPublicKeyCredentialRequestOptions;
};

export const usePasskeyAuth = (isSignin = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const refetchAuthRequest = async (): Promise<AuthenticationRequest | null> => {
    try {
      const response = await axios.get(jsRoutes.com.gemini.web.server.auth.controllers.AuthyController.get().url);
      return response.data.pageProps.authRequest;
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  };
  const initiateAuth = ({
    redirect,
    onSuccess,
    onError,
    email,
    authSignedRequest,
    publicKeyCredentialRequestOptions,
  }: PasskeyAuthTypes) => {
    const assertionReq = decodeAssertionOpts(publicKeyCredentialRequestOptions);

    setIsLoading(true);

    return window.navigator.credentials
      .get({ publicKey: assertionReq })
      .then(assert => {
        // only supports public-key, type narrow here
        if (assert.type === "public-key") return encodeAssertion(assert);
        return null;
      })
      .then(assertion =>
        isSignin
          ? sendCredentialAssertionSignin(assertion, authSignedRequest, redirect)
          : sendCredentialAssertion(assertion, authSignedRequest, redirect, email)
      )
      .then(resp => {
        setIsLoading(false);
        if (onSuccess) {
          onSuccess(resp.data);
        } else {
          window.location.assign(resp.data.redirect);
        }
      })
      .catch((e: AxiosError<{ error: string }> | DOMException) => {
        if (onError) onError(e);
        setIsLoading(false);
        Sentry.captureException(e);
      });
  };

  return { isLoading, initiateAuth, refetchAuthRequest };
};
