import styled from "@emotion/styled";
import { CardColorEnum } from "@gemini-ui/client/credit";
import { Colors, elevation, Flex, Spacing } from "@gemini-ui/design-system";
import { ReactComponent as GeminiIcon } from "@gemini-ui/images/gemini-logo-cyan.svg";
import { ClientState, ClientViews } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const HEADER_HEIGHT = "64px";
const FOOTER_HEIGHT = "76px";

const cardColorBgMap: Record<CardColorEnum, string> = {
  BLACK: Colors.creditCard.subtle.black,
  ROSE: Colors.creditCard.subtle.roseGold,
  SILVER: Colors.creditCard.subtle.silver,
};

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT};
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.scale["2"]};
  z-index: 1;

  @media ${BREAKPOINTS.mobileDownLarge} {
    background-color: ${({ theme }) => theme.colorScheme.background.primary};
  }
`;

export const LogoLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

export const Logo = styled(GeminiIcon)`
  width: 24px;
  height: 24px;
`;

export const HeaderButtonContainer = styled(Flex)`
  height: ${HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.colorScheme.background.primary};
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  gap: ${Spacing.scale["1.5"]};
`;

export const Content = styled(Flex)`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const LeftContent = styled("div")<{ cardColor: CardColorEnum }>`
  width: 50%;
  padding: ${Spacing.scale["8"]} 0 ${Spacing.scale["12"]};
  background-color: ${p => cardColorBgMap[p.cardColor]};
  transition: 250ms ease-in-out;

  @media ${BREAKPOINTS.mobileDownLarge} {
    display: none;
  }
`;

export const RightContent = styled("div")<{ currentState?: ClientViews }>`
  width: 50%;
  overflow-y: auto;
  position: ${({ currentState }) => (currentState === ClientState.AUTH_USER_INVITE ? "unset" : "relative")};
  padding: ${Spacing.scale["12"]} ${Spacing.scale["3"]};
  margin-top: ${HEADER_HEIGHT};
  margin-bottom: ${FOOTER_HEIGHT};
  background-color: ${({ theme }) => theme.colorScheme.background.primary};
  display: ${p => (p.currentState === ClientState.REWARD_SELECTION ? "block" : "grid")};
  align-items: center;
  justify-content: center;

  & > * {
    width: 426px;
    margin: 0 auto;

    @media ${BREAKPOINTS.desktopDownExtraLarge} {
      width: 360px;
    }

    @media ${BREAKPOINTS.tabletDownLarge} {
      width: 100%;
    }

    @media ${BREAKPOINTS.mobileDownLarge} {
      width: 100%;
      max-width: 408px;
    }
  }

  @media ${BREAKPOINTS.mobileDownLarge} {
    width: 100%;
    padding: ${Spacing.scale["2"]};
  }
`;

export const Footer = styled("div")<{ hasSrollShadow: boolean }>`
  position: fixed;
  width: 50%;
  bottom: 0;
  right: 0;
  height: ${FOOTER_HEIGHT};
  background-color: ${({ theme }) => theme.colorScheme.background.primary};
  display: flex;
  box-shadow: ${p => (p.hasSrollShadow ? elevation.stickBar : "none")};

  @media ${BREAKPOINTS.mobileDownLarge} {
    width: 100%;
  }
`;

export const ProgressWrapper = styled.div`
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.colorScheme.progress.background};
`;

export const ProgressBar = styled("div")<{ value: number; max: number }>`
  height: 100%;
  width: ${p => (p.value / p.max >= 1 ? 100 : Math.round((p.value / p.max) * 100))}%;
  background-color: ${({ theme }) => theme.colorScheme.progress.foreground};
  transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
`;

export const ProgressStep = styled(Flex)`
  align-items: center;
  padding-left: ${Spacing.scale["2"]};
`;

export const FooterCenter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${BREAKPOINTS.tabletDownLarge} {
    display: none;
  }
`;
