import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { referralsApi } from "@gemini-ui/client/api";
import { ReferralCodeInfoResponse } from "@gemini-ui/client/credit";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const useGetReferral = (referralCode: string) => {
  const isReferralApplicationEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREDIT_CARD_REFERRAL_APPLICATION
  );
  const [referralInfo, setReferralInfo] = useState<ReferralCodeInfoResponse>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchReferralInfo = async () => {
      setIsLoading(true);
      try {
        const { data } = await referralsApi.getReferralCodeInfo(referralCode);
        setReferralInfo(data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isReferralApplicationEnabled && referralCode) {
      fetchReferralInfo();
    }
  }, [isReferralApplicationEnabled, referralCode]);

  return { referralInfo, isLoading };
};
