import * as Sentry from "@sentry/browser";
import getSentryEnvironment, { ENVIRONMENTS } from "@gemini-ui/sentry/environment";
import { downgradeError } from "@gemini-ui/sentry/helpers";
import { AxiosError } from "@gemini-ui/services/axios";

export const SENTRY_INTEGRATIONS = [
  Sentry.extraErrorDataIntegration(),
  Sentry.captureConsoleIntegration({ levels: ["error", "warn"] }),
];

export const sentryOptions: Sentry.BrowserOptions = {
  // ignore list from https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose", // Facebook borked
    "fb_xd_fragment", // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See https://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived", // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  release: process.env.SENTRY_RELEASE,
  dsn: process.env.SENTRY_DSN,
  environment: getSentryEnvironment(),
  integrations: SENTRY_INTEGRATIONS,
  beforeSend(event, hint: Sentry.EventHint) {
    const axiosError = hint.originalException as AxiosError;

    if (axiosError?.isAxiosError && downgradeError(axiosError)) {
      event.level = `warning`;
    }

    return event;
  },
};

if (ENVIRONMENTS.DEV) {
  Sentry.addIntegration(
    Sentry.breadcrumbsIntegration({
      console: false,
      dom: false,
      fetch: false,
      history: false,
      sentry: false,
      xhr: false,
    })
  );
}
