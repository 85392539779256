import { IJsonTabNode } from "flexlayout-react";
import { FilterType } from "@gemini-ui/pages/ActiveTrader/Spot/constants";

export const PORTAL_PREFIX = "tabSet-menu-";

export const enum PairLayoutComponent {
  NAV = "NAV",
  CHART = "CHART",
  ORDER_BOOK = "ORDER_BOOK",
  EXCHANGE_ACTIVITY = "EXCHANGE_ACTIVITY",
  ORDER_FORM = "ORDER_FORM",
  SUB_LAYOUT = "SUB_LAYOUT",
  TRADING_VIEW_CHART = "TRADING_VIEW_CHART",
  DEPTH_CHART = "DEPTH_CHART",
}

export const PairLayoutComponentValues = [
  PairLayoutComponent.NAV,
  PairLayoutComponent.CHART,
  PairLayoutComponent.ORDER_BOOK,
  PairLayoutComponent.EXCHANGE_ACTIVITY,
  PairLayoutComponent.ORDER_FORM,
  PairLayoutComponent.SUB_LAYOUT,
  PairLayoutComponent.TRADING_VIEW_CHART,
  PairLayoutComponent.DEPTH_CHART,
] as const;

export type CommonLayoutComponent = GeneralLayoutComponent | BlotterTabs;

export const enum GeneralLayoutComponent {
  LEADERBOARD = "LEADERBOARD",
  LEADERBOARD_MOBILE = "LEADERBOARD_MOBILE",
  ACCOUNT_DETAILS = "ACCOUNT_DETAILS",
  ACCOUNTS = "ACCOUNTS",
}

export const GeneralLayoutComponentValues = [
  GeneralLayoutComponent.LEADERBOARD,
  GeneralLayoutComponent.LEADERBOARD_MOBILE,
  GeneralLayoutComponent.ACCOUNT_DETAILS,
  GeneralLayoutComponent.ACCOUNTS,
] as const;

export enum BlotterTabs {
  OPEN_ORDERS = "OPEN_ORDERS",
  ORDER_HISTORY = "ORDER_HISTORY",
  POSITIONS = "POSITIONS",
  BALANCES = "BALANCES",
  TRADE_HISTORY = "TRADE_HISTORY",
  LIQUIDATION = "LIQUIDATION",
  FUNDING = "FUNDING",
  TRANSFERS = "TRANSFERS",
  REALIZED_PNL = "REALIZED_PNL",
  BLOTTER = "BLOTTER",
}

export enum BlotterTabsSpot {
  BALANCES = "BALANCES",
  OPEN_ORDERS = "OPEN_ORDERS",
  ORDER_HISTORY = "ORDER_HISTORY",
  TRADE_HISTORY = "TRADE_HISTORY",
}

export enum BlotterTabsDerivatives {
  OPEN_ORDERS = "OPEN_ORDERS",
  ORDER_HISTORY = "ORDER_HISTORY",
  POSITIONS = "POSITIONS",
  TRADE_HISTORY = "TRADE_HISTORY",
  LIQUIDATION = "LIQUIDATION",
  FUNDING = "FUNDING",
  TRANSFERS = "TRANSFERS",
  REALIZED_PNL = "REALIZED_PNL",
}

export const BlotterTabKeys = Object.keys(BlotterTabs) as Array<keyof typeof BlotterTabs>;

export const BlotterTabsValues = [
  BlotterTabs.OPEN_ORDERS,
  BlotterTabs.ORDER_HISTORY,
  BlotterTabs.POSITIONS,
  BlotterTabs.BALANCES,
  BlotterTabs.TRADE_HISTORY,
  BlotterTabs.LIQUIDATION,
  BlotterTabs.FUNDING,
  BlotterTabs.TRANSFERS,
  BlotterTabs.REALIZED_PNL,
  BlotterTabs.BLOTTER,
] as const;

export interface LayoutTab extends IJsonTabNode {
  imageSrc?: string;
  guard: (modelProps: ModelProps, subLayoutType?: boolean) => boolean;
}

export const BlotterTabsToFilterTypeMap = {
  [BlotterTabs.OPEN_ORDERS]: FilterType.open,
  [BlotterTabs.ORDER_HISTORY]: FilterType.completed,
  [BlotterTabs.POSITIONS]: FilterType.positions,
  [BlotterTabs.BALANCES]: FilterType.balances,
  [BlotterTabs.TRADE_HISTORY]: FilterType.tradeHistory,
  [BlotterTabs.LIQUIDATION]: FilterType.liquidation,
  [BlotterTabs.FUNDING]: FilterType.funding,
  [BlotterTabs.TRANSFERS]: FilterType.transfers,
  [BlotterTabs.REALIZED_PNL]: FilterType.realizedPl,
};

export const enum TabSet {
  CHART = "TABSET-CHART",
  BLOTTERS = "TABSET-BLOTTERS",
  MARKET_AND_ORDERBOOK = "TABSET-MARKET_AND_ORDERBOOK",
  MARKET_TRADES = "TABSET-MARKET_TRADES",
  ORDERBOOK = "TABSET-ORDERBOOK",
  ORDER_FORM = "TABSET-ORDER_FORM",
  ACCOUNT_DETAILS_AND_LEADERBOARD = "ACCOUNT_DETAILS_AND_LEADERBOARD",
  LEADERBOARD = "TABSET-LEADERBOARD",
  ACCOUNT_DETAILS = "TABSET-ACCOUNT_DETAILS",
  ACCOUNTS = "TABSET-ACCOUNTS",
}

export type ModelProps = {
  displayLeaderboardModule: boolean;
  tabletSmDown: boolean;
  isDerivativesAcct: boolean;
  showDerivativeBlotters: boolean;
  isDualMarket: boolean;
  showOnlyOrderForm: boolean;
};

export const TOP = "top";
export type SubLayoutType = "primary" | "secondary";
export const SUBLAYOUT_TYPE: Record<"PRIMARY" | "SECONDARY", SubLayoutType> = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const NAVBAR_SIZE = 48;

export const MenuTabs = [
  BlotterTabs.BALANCES,
  BlotterTabs.FUNDING,
  BlotterTabs.LIQUIDATION,
  BlotterTabs.OPEN_ORDERS,
  BlotterTabs.ORDER_HISTORY,
  BlotterTabs.POSITIONS,
  BlotterTabs.REALIZED_PNL,
  BlotterTabs.TRADE_HISTORY,
  BlotterTabs.TRANSFERS,
] as const;

export function isMenuTab(tab: string): tab is (typeof MenuTabs)[number] {
  return MenuTabs.includes(tab);
}
export const TABSET_BREAKPOINTS = {
  HEIGHT: {
    sm: 264,
  },
};
