// -------------------------- Application --------------------------
// Mixpanel / Braze tracking
// https://www.figma.com/file/hnl5VNqPfwHh4cULrFduoW/Credit-Card-Application?node-id=2453%3A118758&t=M1XgLLQfBNUxLoIk-0
export const CREDIT_CARD_APP_EVENTS = {
  CC_CREDIT_NEW_USER_EMAIL: {
    name: "Register Email",
    properties: {
      ONBOARDING_FLOW: "Onboarding Flow",
    },
  },
  CC_CREDIT_EXISTING_CONFIRM_INFO: {
    name: "CC Confirm Name & Address",
  },
  CC_END_CREDIT_HARDPULL: {
    name: "Complete CC Application (Hard Pull Phase)",
    properties: {
      IS_APPROVED: "Is Approved",
      DENIAL_REASON: "Denial Reason",
      FICO: "FICO Score",
      INCOME: "Income",
    },
  },
  CC_START_APPLICATION: {
    name: "CC Start Application",
  },
  CC_PHONE_CONFIRMATION: {
    name: "CC Phone Confirmation",
  },
  CC_EMAIL_CONFIRMATION: {
    name: "CC Email Confirmation",
  },
  CC_RESEND_EMAIL_CODE: {
    name: "CC Resend Email Code",
  },
  CC_ONFIDO: {
    name: "CC Onfido",
  },
  CC_SOFT_PULL_APPROVED: {
    name: "CC Soft Pull Approved",
    properties: {
      CREDIT_LIMIT_MIN: "Credit Limit Min",
      CREDIT_LIMIT_MAX: "Credit Limit Max",
      APR_MIN: "APR Min",
      APR_MAX: "APR Max",
    },
  },
  CC_INCOME: {
    name: "CC Income",
  },
  CC_TERMS_REVIEW: {
    name: "CC Terms Review",
    properties: {
      APR_MIN: "APR Min",
      APR_MAX: "APR Max",
    },
  },
  CC_TERMS_AGREE: {
    name: "CC Terms Agree",
    properties: {
      APR_MIN: "APR Min",
      APR_MAX: "APR Max",
    },
  },
  CC_HARD_PULL_APPROVED: {
    name: "CC Hard Pull Approved",
    properties: {
      CREDIT_LIMIT: "Credit Limit",
      TEMPORARY_CREDIT_LIMIT: "Temporary Credit Limit",
      APR: "APR",
    },
  },
  CC_CUSTOMIZE_CARD: {
    name: "CC Customize Card",
  },
  CC_SEARCH_CRYPTO: {
    name: "CC Search Crypto",
    properties: {
      SEARCH_TERM: "Search Term",
    },
  },
  CC_CHOOSE_CRYPTO: {
    name: "CC Choose Crypto",
    properties: {
      CRYPTO_REWARD: "Crypto Reward",
    },
  },
  CC_RECENT_APP_DENIED: {
    name: "CC Recent App Denied",
  },
  CC_SOFT_PULL_DENIED: {
    name: "CC Soft Pull Denied",
  },
  CC_BIOMETRIC_DENIED: {
    name: "CC Biometric Denied",
  },
  CC_KYC_DENIED: {
    name: "CC KYC Denied",
  },
  CC_DECEASED: {
    name: "CC Deceased",
  },
  CC_SOFT_PULL_FROZEN: {
    name: "CC Soft Pull Frozen",
  },
  CC_HARD_PULL_FROZEN: {
    name: "CC Hard Pull Frozen",
  },
  CC_INELIGIBLE: {
    name: "CC Ineligible",
  },
  CC_SERVICE_FAILURE: {
    name: "CC Service Failure",
  },
  CC_MANUAL_REVIEW: {
    name: "CC Manual Review",
  },
  CC_BEGIN_CREDIT_PREQUAL: {
    name: "CC Start Prequalify Application",
    properties: {
      AUTH_USER: "Auth User",
    },
  },
  CC_END_CREDIT_PREQUAL: {
    name: "CC Prequalify Application Completed",
    properties: {
      IS_APPROVED: "Is Approved",
      DENIAL_REASON: "Denial Reason",
    },
  },
  CC_BEGIN_CREDIT_HARDPULL: {
    name: "CC Hard Pull Submitted",
  },
  CC_COLOR_CHOSEN: {
    name: "CC Card Color Chosen",
    properties: {
      CARD_COLOR: "Color Choice",
      AUTH_USER: "Auth User",
    },
  },
  CC_EXISTING_START_VERIFICATION: {
    name: "CC Start Verification",
    properties: {
      AUTH_USER: "Auth User",
    },
  },
};

export enum CreditAnalyticsEvents {
  StartApplication = "start_application",
  Registration = "registration",
  StartPrequal = "start_prequal",
  ConfirmNameAndAddress = "confirm_name_address",
  CompletePrequal = "complete_prequal",
  CompleteHardpull = "complete_hardpull",
}

export enum CreditAnalyticsEventLabels {
  Approved = "approved",
  Denied = "denied",
}

export enum TwitterCreditCardEvents {
  ccApplicationComplete = "tw-o69hm-o6g4g",
  StartCreditPrequal = "tw-o69hm-o8bqg",
  CompleteCreditPrequal = "tw-o69hm-o8bqf",
  StartApplication = "tw-o69hm-oc64u",
  ConfirmNameAndAddress = "tw-o69hm-oc64q",
}

export enum BingCreditCardEvents {
  ccApplicationComplete = "CCApplicationComplete",
  ccStartApplication = "CCStartApplication",
  ccConfirmNameAddress = "CCConfirmNameAddress",
  ccPrequalApproved = "CCPrequalApproved",
}

export enum TikTokCreditCardEvent {
  ccApplicationComplete = "ViewContent",
}

export enum RedditCreditCardEvent {
  ccApplicationComplete = "ViewContent",
}

// -------------------------- Dashboard --------------------------
export const CREDIT_CARD_PAYMENT_EVENTS = {
  CC_DASHBOARD_CURRENT_BALANCE_TILE_CLICK_PAY: {
    name: "CC Dashboard - Current Balance Tile - Click - Pay",
  },
  CC_DASHBOARD_CURRENT_BALANCE_TILE_CLICK_AUTOPAY: {
    name: "CC Dashboard - Current Balance Tile - Click - AutoPay",
  },
  CC_MAKE_A_PAYMENT_CLICK_PAY: {
    name: "CC Make a Payment - Click - Pay",
  },
  CC_MAKE_A_PAYMENT_ACH_AUTHORIZATION_CLICK_AGREE: {
    name: "CC Make a Payment - ACH Authorization - Click - Agree",
  },
  CC_MANAGE_AUTOPAY_CLICK_UPDATE_AUTOPAY: {
    name: "CC Manage AutoPay - Click - Update AutoPay",
  },
  CC_MANAGE_AUTOPAY_CONFIRM_AUTOPAY_CLICK_CONFIRM: {
    name: "CC Manage AutoPay - Confirm AutoPay - Click - Confirm",
  },
  CC_MANAGE_AUTOPAY_TURN_OFF_AUTOPAY_CLICK_TURN_OFF: {
    name: "CC Manage AutoPay - Turn off AutoPay? - Click - Turn off",
  },
};

export const CREDIT_CARD_DASHBOARD_CARD_TILE_V2 = {
  CC_DASHBOARD_CARD_TILE_V2_CLICK_ACTIVATE: {
    name: "CC Dashboard - Card Tile V2 - Click - Activate",
    properties: {
      IS_AU: "Is AU",
      IS_ONFIDO_VERIFIED: "Is Onfido Verified",
      REASON_TO_ACTIVATE: "Reason to Activate",
    },
  },
  CC_DASHBOARD_CARD_TILE_V2_CLICK_DETAILS: {
    name: "CC Dashboard - Card Tile V2 - Click - Details",
    properties: {
      IS_AU: "Is AU",
      IS_ONFIDO_VERIFIED: "Is Onfido Verified",
      IS_EXPIRING_SOON: "Is Expiring Soon",
      IS_EXPIRED: "Is Expired",
    },
  },
  CC_DASHBOARD_CARD_TILE_V2_CLICK_UNLOCK: {
    name: "CC Dashboard - Card Tile V2 - Click - Unlock",
    properties: {
      IS_AU: "Is AU",
    },
  },
  CC_DASHBOARD_CARD_TILE_V2_CLICK_VERIFY: {
    name: "CC Dashboard - Card Tile V2 - Click - Verify",
  },
};

export const CREDIT_CARD_ACTIVATE_CARD_EVENTS = {
  CC_DASHBOARD_CARD_TILE_CLICK_ACTIVATE_CARD: {
    name: "CC Dashboard - Card Tile - Click - Activate Card",
    properties: {
      IS_FIRST_CARD: "Is First Card",
    },
  },
  CC_CARD_SETTINGS_CLICK_ACTIVATE_CARD: {
    name: "CC Card Settings - Click - Activate Card",
    properties: {
      IS_FIRST_CARD: "Is First Card",
    },
  },
};

export const CREDIT_CARD_WARM_WELCOME_EVENTS = {
  CC_WARM_WELCOME_CLICK_ACTIVATE: {
    name: "CC Warm Welcome - Click - Activate",
  },
  CC_WARM_WELCOME_CLICK_VERIFY_IDENTITY: {
    name: "CC Warm Welcome - Click - Verify Identity",
  },
  CC_WARM_WELCOME_CLICK_TEMPORARY_CREDIT_LINE: {
    name: "CC Warm Welcome - Click - Temporary Credit Line",
  },
  CC_WARM_WELCOME_CLICK_REWARDS_CATEGORIES: {
    name: "CC Warm Welcome - Click - Rewards Categories",
  },
  CC_WARM_WELCOME_CLICK_ADD_PAYMENT_METHOD: {
    name: "CC Warm Welcome - Click - Add Payment Method",
  },
  CC_WARM_WELCOME_CLICK_VIEW_MORE: {
    name: "CC Warm Welcome - Click - View More",
  },
  CC_WARM_WELCOME_CLICK_VIEW_LESS: {
    name: "CC Warm Welcome - Click - View Less",
  },
  CC_WARM_WELCOME_CLICK_EXPLORE_BENEFITS: {
    name: "CC Warm Welcome - Click - Explore Benefits",
  },
};

export const CREDIT_CARD_DISPUTES_EVENTS = {
  CC_DISPUTES_CLICK_REPORT_AN_ISSUE: {
    name: "CC Disputes - Click - Report An Issue",
    properties: {
      TRANSACTION_SIZE: "Transaction Size",
    },
  },
  CC_DISPUTES_CLICK_SELECT_REASON: {
    name: "CC Disputes - Click - Select Reason",
    properties: {
      REASON: "Reason",
    },
  },
  CC_DONT_RECOGNIZE_CLICK_ACTIONS: {
    name: "CC Don't Recognize - Click - Actions",
    properties: {
      ACTIONS: "Actions",
    },
  },
  CC_DEACTIVATE_CONFIRM_DEACTIVATE_CARD: {
    name: "CC Deactivate - Confirm - Deactivate Card",
    properties: {
      ADDRESS_ACTIONS: "Address Actions",
    },
  },
  CC_DISPUTES_CLICK_ADD_DOCUMENTS: {
    name: "CC Disputes - Click - Add Documents",
    properties: {
      DOCUMENTS: "Documents",
    },
  },
  CC_DISPUTES_CLICK_HAVE_QUESTIONS: {
    name: "CC Disputes - Click - Have Questions",
  },
  CC_DISPUTES_BYPASS_DETAILS: {
    name: "CC Disputes - Bypass - Details",
  },
  CC_DISPUTES_ADD_DETAILS: {
    name: "CC Disputes - Add - Details",
  },
  CC_DISPUTES_SUBMITTED_DISPUTE: {
    name: "CC Disputes - Submitted - Dispute",
  },
  CC_DISPUTES_CLICK_CANCEL_DISPUTE: {
    name: "CC Disputes - Click - Cancel Dispute",
  },
  CC_DISPUTES_CLICK_EXIT: {
    name: "CC Disputes - Click - Exit",
    properties: {
      ACTIONS: "Actions",
    },
  },
};

export const CREDIT_CARD_REWARD_DISPUTES_EVENTS = {
  CC_REWARD_DISPUTES_CLICK_REPORT_AN_ISSUE: {
    name: "CC Reward Disputes - Click - Report An Issue",
  },
  CC_REWARD_DISPUTES_CLICK_SELECT_REASON: {
    name: "CC Reward Disputes - Click - Select Reason",
    properties: {
      REASON: "Reason",
    },
  },
  CC_REWARD_DISPUTES_CLICK_MORE_INFO_REWARD_ADJUSTMENT: {
    name: "CC Reward Disputes - Click - More Info Reward Adjustment",
  },
  CC_REWARD_DISPUTES_BYPASS_DETAILS: {
    name: "CC Reward Disputes - Bypass - Details",
  },
  CC_REWARD_DISPUTES_ADD_DETAILS: {
    name: "CC Reward Disputes - Add - Details",
  },
  CC_REWARD_DISPUTES_SUBMITTED_DISPUTE: {
    name: "CC Reward Disputes - Submitted - Dispute",
  },
  CC_REWARD_DISPUTES_CLICK_CANCEL_DISPUTE: {
    name: "CC Reward Disputes - Click - Cancel Dispute",
  },
  CC_REWARD_DISPUTES_CLICK_EXIT: {
    name: "CC Reward Disputes - Click - Exit",
    properties: {
      ACTIONS: "Actions",
    },
  },
};

export const CREDIT_CARD_BENEFITS_AND_OFFERS_EVENTS = {
  CC_DASHBOARD_BENEFITS_AND_OFFERS_TILE_CLICK_VIEW_ALL: {
    name: "CC Dashboard - Benefits and Offers Tile - Click - View All",
  },
};

export const CREDIT_CARD_REFERRAL_EVENTS = {
  CC_REFERRAL_CARD_CLICK_DETAIL_TERMS: {
    name: "CC Referral Card - Click - Detail & Terms",
  },
  CC_REFERRAL_CARD_CLICK_SHARE: {
    name: "CC Referral Card - Click - Share",
  },
};

// -------------------------- Exchange Homepage --------------------------
export const CREDIT_CARD_HOMEPAGE_EVENTS = {
  CC_HOME_CLICK_PAY: {
    name: "CC Home - Click - Pay",
    properties: {
      PAST_DUE: "Is payment past due",
    },
  },
  CC_HOME_CLICK_VIEW_CARD: {
    name: "CC Home - Click - View card",
  },
  CC_HOME_CLICK_AUTOPAY: {
    name: "CC Home - Click - AutoPay",
  },

  CC_HOME_CLICK_VIEW_INVITE: {
    name: "CC Home - Click - View invite",
  },
  CC_HOME_CLICK_SEE_IF_YOU_PREQUALIFY: {
    name: "CC Home - Click - See if you pre-qualify",
  },
  CC_HOME_CLICK_GET_THE_CARD: {
    name: "CC Home - Click - Get the card",
  },
  CC_HOME_CLICK_CONTINUE_APPLICATION: {
    name: "CC Home - Click - Continue application",
  },
  CC_HOME_PROMO_CLICK_APPLY_NOW: {
    name: "CC Home Promo - Click - Apply Now",
    properties: {
      Reward: "Reward",
    },
  },
  CC_HOME_PROMO_CLICK_CONTINUE_APPLICATION: {
    name: "CC Home Promo - Click - Continue Application",
    properties: {
      Reward: "Reward",
    },
  },
  CC_HOME_PROMO_CLICK_LEARN_MORE: {
    name: "CC Home Promo - Click - Learn More",
    properties: {
      Reward: "Reward",
    },
  },
};

export const CREDIT_CARD_INTRO_MODAL_EVENTS = {
  CC_INTRO_MODAL_PROMO_CLICK_NEXT: {
    name: "CC Intro Modal - Promo - Click - Next",
    properties: {
      Title: "Title",
      Reward: "Reward",
    },
  },
  CC_INTRO_MODAL_PROMO_CLICK_PREQUALIFY: {
    name: "CC Intro Modal - Promo - Click - Prequalify",
    properties: {
      Title: "Title",
      Reward: "Reward",
    },
  },
  CC_INTRO_MODAL_PROMO_CLICK_EXIT: {
    name: "CC Intro Modal - Promo - Click - Exit",
    properties: {
      Title: "Title",
      Reward: "Reward",
    },
  },
  CC_INTRO_MODAL_SLIDE_1_CLICK_NEXT: {
    name: "CC Intro Modal - Slide 1 - Click - Next",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_1_CLICK_BACK: {
    name: "CC Intro Modal - Slide 1 - Click - Back",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_1_CLICK_PREQUALIFY: {
    name: "CC Intro Modal - Slide 1 - Click - Prequalify",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_1_CLICK_EXIT: {
    name: "CC Intro Modal - Slide 1 - Click - Exit",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_2_CLICK_NEXT: {
    name: "CC Intro Modal - Slide 2 - Click - Next",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_2_CLICK_BACK: {
    name: "CC Intro Modal - Slide 2 - Click - Back",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_2_CLICK_PREQUALIFY: {
    name: "CC Intro Modal - Slide 2 - Click - Prequalify",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_2_CLICK_EXIT: {
    name: "CC Intro Modal - Slide 2 - Click - Exit",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_3_CLICK_NEXT: {
    name: "CC Intro Modal - Slide 3 - Click - Next",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_3_CLICK_BACK: {
    name: "CC Intro Modal - Slide 3 - Click - Back",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_3_CLICK_PREQUALIFY: {
    name: "CC Intro Modal - Slide 3 - Click - Prequalify",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_3_CLICK_EXIT: {
    name: "CC Intro Modal - Slide 3 - Click - Exit",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_4_CLICK_BACK: {
    name: "CC Intro Modal - Slide 4 - Click - Back",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_4_CLICK_PREQUALIFY: {
    name: "CC Intro Modal - Slide 4 - Click - Prequalify",
    properties: {
      Title: "Title",
    },
  },
  CC_INTRO_MODAL_SLIDE_4_CLICK_EXIT: {
    name: "CC Intro Modal - Slide 4 - Click - Exit",
    properties: {
      Title: "Title",
    },
  },
};
